import React from "react";
import {
    FormGroup, Input, Button, Label, Row, Col, Collapse, Modal, FormFeedback, UncontrolledTooltip, Alert, Spinner, InputGroupAddon, InputGroup, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Container
} from "reactstrap";
import CustomCalendar from '../../gc-comps/fragments/CustomCalendar.js';
import { useHistory } from "react-router-dom";
import {validateFormFields, getSelectedWeekdayCount} from '../../gc-comps/utility/CommonHandlers.js';
import axios from 'axios';
import TermsModal from '../../gc-comps/fragments/TermsModal.js';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import Loader from "react-js-loader";
import { get, isNumber} from 'lodash'
import { searchProxyAPIKey, searchProxyAPIUrl, emailAPIKey, emailAPIUrl } from "../../config.js";


var moment = require('moment-timezone');

export const STORES_ONLINE_LESSON_ONLY = ["8550", "7346", "3021", "8901"]

const lessonClosedTerms = new Event('LESSON_CLOSED_TERMS');
var termsModalEvent = {
    'event' : 'LESSON_CLOSED_TERMS'
};

function BookNowModal(props) {
    const ERROR_MESSAGES = {
      emptyCardNumber: 'Please enter a Credit Card number',
      invalidCardNumber: 'Please enter a valid Credit Card number',
      emptyExpiryDate: 'Please enter an expiration date',
      monthOutOfRange: 'Please enter a valid month',
      yearOutOfRange: 'Please enter a valid year',
      dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
      invalidExpiryDate: 'Please enter a valid expiration date',
      emptyCVC: 'Please enter a CVC',
      invalidCVC: 'Please enter a valid CVC'
    }

    const {
        meta,
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps
      } = usePaymentInputs({errorMessages: ERROR_MESSAGES});

    const timeThreshold = new Date(new Date().getTime() + 48 * 60 * 60 * 1000);
    const filteredAvailability = props.instructor.availability.filter(
      (timeSlot) => {
        const startTimeStr = timeSlot.split(';')[0];
        const startTime = new Date(startTimeStr);
        return startTime > timeThreshold;
      }
    );
    props.instructor.availability = filteredAvailability;

    const instrumentSet = props.instructor.instruments.map((value, index) => {
        return { value: value, label: value }
    }
    );

    const customStyles = {
       'inputWrapper': {
         'base': {
           borderColor: 'dc3545',
           height: '40px !important',
           padding: '7px 12px',
           boxShadow: 'none'
         },
         'focused': {
           borderColor: '#dc3545',
           boxShadow: 'unset'
         }
       },
       'input': {
         'base': {
             boxShadow: 'none'
         },
         'cardNumber': {
           width: '18.8rem'
         },
         'expiryDate': {
           width: '10rem'
         },
         'cvc': {
           width: '5rem'
         }
       },
       'errorText': {
         'base': {
           color: 'maroon',
           display: 'none'
         }
       }
     };
    var selectedInstrument = {};
    var vUserSelections = JSON.parse(localStorage.getItem('userSelections'));
    if(vUserSelections && vUserSelections.instrument && vUserSelections.instrument.length>0) {
        var instumentsSel = vUserSelections.instrument;
        if(instumentsSel.length === 1 && instumentsSel[0] !== 'Not sure'){
            selectedInstrument = {value: instumentsSel[0], label: instumentsSel[0]};
        }
    }
    const _30price = parseFloat(props.instructor['thirtyprice']);
    const _60price = parseFloat(props.instructor['sixtyprice']);
    const[instrument, setInstrument] = React.useState(selectedInstrument && selectedInstrument.value ? selectedInstrument : instrumentSet[0]);
    var defaultMode = props.instructor.activityFull && (props.instructor.activityFull.includes('In-Store '+instrument.value) ||  props.instructor.activityFull.includes('In-Store '+instrument.value+' Lesson'))
         ? 'in-store' : 'online';
    if(vUserSelections && vUserSelections.lessonMode){
        var userSelMode = vUserSelections.lessonMode;
        if(!Array.isArray(userSelMode)){
            userSelMode = [userSelMode];
        }

        if(userSelMode.length === 1){
            defaultMode = userSelMode[0].toLowerCase();
        }
    }

    const priceMap = {
        '30' : _30price,
        '60' : _60price
    }
    var allowToSchedule = props.instructor.allowToSchedule;
    if(!allowToSchedule){
        var enableStoreList = process.env.REACT_APP_ENABLE_STORE_LIST;
        if(enableStoreList && enableStoreList.length > 0 && enableStoreList.includes(',')){
            var storeListArr = enableStoreList.split(',');
            allowToSchedule = storeListArr.includes(props.instructor.storeNumber);
        }
    }

    const defaultAtcErrorMsg = 'Uh Oh! Something went wrong. Please try again.';
    var cardNumber;
    var expiry;
    var cvc;

    const[firstNameValid, setFirstNameValid] = React.useState('');
    const[lessonPackPrice, setLessonPackPrice] = React.useState('');
    const[dropDownOpen, setDropDownOpen] = React.useState(false);
    const[parentNameValid, setParentNameValid] = React.useState('');
    const[parentFirstNameValid, setParentFirstNameValid] = React.useState('');
    const[parentLastNameValid, setParentLastNameValid] = React.useState('');
    const[otherNameValid, setOtherNameValid] = React.useState('');
    const[otherFirstNameValid, setOtherFirstNameValid] = React.useState('');
    const[otherLastNameValid, setOtherLastNameValid] = React.useState('');
    const[lastNameValid, setLastNameValid] = React.useState('');
    const[emailValid, setEmailValid] = React.useState('');
    const[cardNumberValid, setCardNumberValid] = React.useState('');
    const[spinner, setSpinner] = React.useState('');
    const[expiryValid, setExpiryValid] = React.useState('');
    const[cvcValid, setCvcValid] = React.useState('');
    const[address1Valid, setAddress1Valid] = React.useState('');
    const[address2Valid, setAddress2Valid] = React.useState('');
    const[cityValid, setCityValid] = React.useState('');
    const[stateaddrValid, setStateaddrValid] = React.useState('');
    const[zipValid, setZipValid] = React.useState('');
    const[associateidValid, setAssociateidValid] = React.useState('');
    const[employeeNameValid, setEmployeeNameValid] = React.useState('');
    const[phoneNumberValid, setPhoneNumberValid] = React.useState('');
    const [cardNumberVar, setCardNumberVar] = React.useState('');
    const [expiryVar, setExpiryVar] = React.useState('');
    const [cvcVar, setCvcVar] = React.useState('');
    const[bookStep, setBookStep] = React.useState(2);
    const[duration, setDuration] = React.useState("30");
    const[count, setCount] = React.useState(4);
    const[price, setPrice] = React.useState(priceMap[duration]);
    const[timeSlot, setTimeSlot] = React.useState("");
    const[time, setTime] = React.useState("");
    const[startDate, setStartDate] = React.useState("");
    const[desc, setDesc] = React.useState("");
    const[finalAction, setFinalAction] = React.useState(allowToSchedule ? "SCHEDULE" : "ADD TO CART");
    const[instInvalid, setInstInvalid] = React.useState(false);
    const[scheduleNow, setScheduleNow] = React.useState(allowToSchedule ? "yes" : "later");
    const[scheduleModal, setScheduleModal] = React.useState(false);
    const [contactInstructor, setContactInstructor] = React.useState(props.instructor);
    const [lessonType, setLessonType] = React.useState(defaultMode);
    const [firstName, setFirstName] = React.useState('');
    const [parentName, setParentName] = React.useState('');
    const [parentFirstName, setParentFirstName] = React.useState('');
    const [parentLastName, setParentLastName] = React.useState('');
    const [otherName, setOtherName] = React.useState('');
    const [otherFirstName, setOtherFirstName] = React.useState('');
    const [otherLastName, setOtherLastName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [custEmail, setCustEmail] = React.useState('');
    const [orderNo, setOrderNo] = React.useState('');
    const [regPrice, setRegPrice] = React.useState('');
    const [discount, setDiscount] = React.useState('');
    const [orderTotal, setOrderTotal] = React.useState('');
    const [currentCoupon, setCurrentCoupon] = React.useState('');
    const [coupons, setCoupons] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [giftCardNumber, setGiftCardNumber] = React.useState('');
    const [giftCardBalance, setGiftCardBalance] = React.useState('');
    const [giftCardAmount, setGiftCardAmount] = React.useState('');
    const [couponErrorMessage, setCouponErrorMessage] = React.useState('');
    const [isEmployeeOrder, setIsEmployeeOrder] = React.useState('');

    const [address1, setAddress1] = React.useState('');
    const [address2, setAddress2] = React.useState('');
    const [city, setCity] = React.useState('');
    const [stateaddr, setStateAddr] = React.useState('');
    const [zip, setZip] = React.useState('');
    const [associateid, setAssociateId] = React.useState('');
    const [employeeName, setEmployeeName] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const[gradeLevel, setGradeLevel] = React.useState('');
    const[formValid, setFormValid] = React.useState(true);
    const [studentTaking, setStudentTaking] = React.useState("myself");
    const[termsModal, setTermsModal] = React.useState(false);
    const[utcFormatStartTime, setUtcFormatStartTime] = React.useState('');
    const[ackMsg, setAckMsg] = React.useState('success');
    const[sLoading, setSLoading] = React.useState(false);
    const[atcErrorMessage, setAtcErrorMessage] = React.useState('');
    const[readableSlotSel, setReadableSlotSel] = React.useState('');
    const[selWeekdayCount, setSelWeekdayCount] = React.useState('');
    const[termsAccepted, setTermsAccepted] = React.useState(finalAction === 'SCHEDULE' ? true : false);
    const[priceAlertMsg, setPriceAlertMsg] = React.useState(moment().isBetween('2022-01-28','2022-02-23','day', '[]'));
    const[priceLoading, setPriceLoading] = React.useState(false);
    const [timeUnavailable, setTimeUnavailable] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener('message', (event) => {
            if(event.origin === 'https://mac-test-virto.miretail.com' || event.origin === 'https://www.musicarts.com' ||
                        (process.env.REACT_APP_WORK_ENV === 'test' && event.origin === 'http://localhost:3000')){
                //valid origins
                if(event.data.event === 'maAddToCartError'){
                    console.log("Error event encountered :: ", JSON.stringify(event.data, null, 2));
                    if(event.data.data && event.data.data.message){
                        setAtcErrorMessage(event.data.data.message);
                    }else{
                        setAtcErrorMessage(defaultAtcErrorMsg);
                    }
                    setSLoading(false);
                    setAckMsg('failure');
                }

            }else{
                console.warn('ECF_INTD_AMS', " :: Unauthorized access");
            }
          });
    }, []);


    React.useEffect(() => {
        if(firstNameValid === 'valid' && lastNameValid === 'valid' && emailValid === 'valid'){
            switch(studentTaking){
                case 'child':
                    if((validateFormFields(gradeLevel, "custName") === 'valid') && parentFirstNameValid==='valid' && parentLastNameValid==='valid'){
                        setFormValid(true && termsAccepted);
                    }else{
                        setFormValid(false && termsAccepted);
                    }
                    break;
                case 'other':
                    if(otherFirstNameValid==='valid' && otherLastNameValid==='valid'){
                        setFormValid(true && termsAccepted);
                    }else{
                        setFormValid(false && termsAccepted);
                    }
                    break;
                default:
                    setGradeLevel('');
                    setFormValid(true && termsAccepted);
            }

            if(finalAction==='ADD TO CART' || finalAction==='paymentError'||finalAction==='paymentSuccess')
            {
                if(meta.error || address1Valid!='valid' || cityValid!='valid' || stateaddrValid!='valid' || zipValid!='valid' || phoneNumberValid!='valid' || associateidValid!='valid' || employeeNameValid!='valid')
                {
                    setFormValid(false && termsAccepted);
                }
            }
        }else{
            setFormValid(false && termsAccepted);
        }
    }, [firstNameValid, lastNameValid, emailValid, gradeLevel, studentTaking, termsAccepted, meta.error, address1Valid, cityValid, stateaddrValid, zipValid, phoneNumberValid, associateidValid, employeeNameValid, parentFirstNameValid, parentLastNameValid, otherFirstNameValid, otherLastNameValid]);

    const applyCoupon = ({target}) => {
        if(custEmail.toLowerCase().includes('@musicarts.com') || custEmail.toLowerCase().includes('@guitarcenter.com') || custEmail.toLowerCase().includes('@musiciansfriend.com'))
        {
            if(currentCoupon!='freereg')
            {
                setCouponErrorMessage('Employee discount already applied. Invalid coupon');
                return;
            }
        }

        if(!coupons.includes(currentCoupon) && currentCoupon!='')
        {
            coupons.push(currentCoupon.toLowerCase());
            priceCart();
        }
        setCurrentCoupon('');
    }

    const changeInstrument = ({target}) => {
       if(!(props.instructor.activityFull && (props.instructor.activityFull.includes('In-Store '+target.value) ||  props.instructor.activityFull.includes('In-Store '+target.value+' Lesson'))))
       {
          setLessonType('online');
       }
       else
       {
          setLessonType('in-store');
       }
       setInstrument({value: target.value, label: target.value});

    }

    const checkGiftCardBalance = ({target}) => {
        let path = process.env.REACT_APP_ATG_HOST+'/rest/model/atg/checkout/checkoutActor/getRemainingBalance';

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                "x-api-key": "gPtXpHoE0HdHbAwTRpLBaF4qExAcigcG",
                "Request-Credential": "quote$zombie!reap"
            }
        };

        let checkGiftCardBalanceRequest = {
            'accountNumber': giftCardNumber
       };

        axios.post(path, checkGiftCardBalanceRequest, axiosConfig)
        .then(res => {
            if(res.data)
            {
                if(res.data.result && res.data.result.message && res.data.result.message.RemainingBalance>0)
                {
                    setGiftCardBalance(
                      Number(res.data.result.message.RemainingBalance)
                    );

                    if(res.data.result.message.RemainingBalance>orderTotal)
                    {
                        setGiftCardAmount(orderTotal);
                    }
                    else
                    {
                        setGiftCardAmount(res.data.result.message.RemainingBalance);
                    }
                }
                else
                {
                    const responseText =
                      get(res, "data.result.message.ResponseText") ||
                      get(res, "data.result.message") ||
                      "Insufficient Balance";

                    setGiftCardBalance(responseText);
                    setGiftCardNumber('');
                }
            }
        });
    }

    const ddtoggle = () => {
        setDropDownOpen(!dropDownOpen);
    }

    const removeCoupon = ({target}) => {
        coupons.length=0;
        setCoupons([]);
        priceCart();
    }

    const removeGiftCard = ({target}) => {
        setGiftCardAmount('');
        setGiftCardNumber('');
        setGiftCardBalance('');
    }

    const handleCouponChange = event => {
        setCurrentCoupon(event.target.value);
    }

    const handleGiftCardChange = event => {
        setGiftCardNumber(event.target.value);
    }

    const handleSelection = ({ target }) => {
        if(target.name === 'lessonDuration'){
            setDuration(target.value);
            setPrice(priceMap[target.value]);
            setStartDate('');
            setDesc('');
            setTimeSlot('');
            if(scheduleNow === 'yes'){
                setFinalAction("SCHEDULE");
                setTermsAccepted(true);
            }else{
                setFinalAction("ADD TO CART");
                priceCart();
                setTermsAccepted(false);
            }
        }else if(target.name === 'next1'){
            setBookStep(2);
        }
        else if(target.name === 'next2'){
            setBookStep(3);
            setSpinner(true);
            var atcEvent = {
                'event' : 'LESSON_ADD_TO_CART'
            };
            if(process.env.REACT_APP_WORK_ENV === 'test'){
                console.log("In Test :: Req :: ", JSON.stringify(atcEvent, null, 2));
            }

            axios
                .post(
                    `${searchProxyAPIUrl}/iquery`,
                        '{"query": {"match": { "instructorId.keyword":"' +  props.instructor.instructorId + '"} } }\n',
                    {
                        headers: {
                            "x-api-key": searchProxyAPIKey
                        },
                    }
                )
              .then((res) => {
                if (scheduleNow === 'later') {
                  window.parent.postMessage(atcEvent, '*');
                  return routeChange();
                }
                    const availableTimes = res?.data?.responses?.[0]?.hits?.hits?.[0]?._source?.availability
                    const formatStr = "YYYY-MM-DDTHH:mm";
                    const lessons = [];
                    const startTime = moment(startDate + " " + time);
                    lessons.push(`${startTime.format(formatStr)};${moment(startTime).add(30, "minutes").format(formatStr)}`)
                    if(duration === '60'){
                        lessons.push(`${moment(startTime).add(30, "minutes").format(formatStr)};${moment(startTime).add(60, "minutes").format(formatStr)}`)
                    }
                    const selectedTimeIsAvailable = lessons.every(lessonTime => availableTimes.some((t) =>t.startsWith(lessonTime)))

                    if (selectedTimeIsAvailable) {

                        window.parent.postMessage(atcEvent, '*');
                        routeChange();
                    } else {
                        setTimeUnavailable(true);
                    }
                })
                .catch((e) => {
                    window.parent.postMessage(atcEvent, '*');
                    routeChange();
                })
        }else if(target.name === 'schdl'){
            setScheduleModal(true);
        }else if(target.name === 'scheduleNow'){
            setScheduleNow(target.value);
            if(target.value === 'yes' && !timeSlot){
                setFinalAction("SCHEDULE");
                setTermsAccepted(true);
                var atcEvent = {
                    'event' : 'LESSON_ACCEPT_TERMS'
                };
                if(process.env.REACT_APP_WORK_ENV === 'test'){
                    console.log("In Test :: Req :: ", JSON.stringify(atcEvent, null, 2));
                }
                window.parent.postMessage(atcEvent, '*');
            }else{
                setFinalAction("ADD TO CART");
                priceCart();
                setTermsAccepted(false);
            }
        }else if(target.name === 'lessonCount'){
            setCount(target.value);
            var lessonPrice = priceMap[duration];
            setPrice(lessonPrice * target.value);
        } else if (target.value === 'in-store') {
            setLessonType('in-store');
            setStartDate('');
            setDesc('');
            setTimeSlot('');
            if(scheduleNow === 'yes'){
                setFinalAction("SCHEDULE");
                setTermsAccepted(true);
            }else{
                setFinalAction("ADD TO CART");
                priceCart();
                setTermsAccepted(false);
            }
        } else if (target.value === 'online') {
            setLessonType('online');
            setStartDate('');
            setDesc('');
            setTimeSlot('');
            if(scheduleNow === 'yes'){
                setFinalAction("SCHEDULE");
                setTermsAccepted(true);
            }else{
                setFinalAction("ADD TO CART");
                priceCart();
                setTermsAccepted(false);
            }
        } else if (target.name === 'first-name') {
            setFirstName(target.value.trim());
            setFirstNameValid(validateFormFields(target.value, "custName"));
        } else if (target.name === 'last-name') {
            setLastName(target.value.trim());
            setLastNameValid(validateFormFields(target.value, "custName"));
        } else if (target.name==='student-taking') {
            setParentName('');
            setOtherName('');
            setStudentTaking(target.value);
            if(target.value==='child')
            {

            }
        } else if (target.name === 'cust-email') {
            setCustEmail(target.value.trim());
            if (target.classList.contains('is-invalid')) {
                setEmailValid(validateFormFields(target.value, "custEmail"));
            }
            if(validateFormFields(target.value, "custEmail")==='valid')
            {
                setEmailValid(validateFormFields(target.value, "custEmail"));
            }
        } else if(target.name === 'grade-level'){
            setGradeLevel(target.value);
        } else if(target.name === 'termsAccepted'){
            //console.log("target.value :: ", target.checked);
            if(finalAction === 'ADD TO CART'){
                setTermsAccepted(target.checked);
            }else{
                setTermsAccepted(true);
            }
        }
        else if (target.name === 'cardNumber') {
            setCardNumberVar(target.value);
            setCardNumberValid(validateFormFields(target.value, "cardNumber"));
        }
        else if (target.name === 'expiryDate') {
            setExpiryVar(target.value);
            setExpiryValid(validateFormFields(target.value, "expiry"));
        }
        else if (target.name === 'cvc') {
            setCvcVar(target.value);
            setCvcValid(validateFormFields(target.value, "cvc"));
        }
        else if (target.name === 'address-line1') {
            setAddress1(target.value.trim());
            setAddress1Valid(validateFormFields(target.value, "paymentForm"));
        }
        else if (target.name === 'address-line2') {
            setAddress2(target.value.trim());
            setAddress2Valid(validateFormFields(target.value, "address2"));
        }
        else if (target.name === 'city') {
            setCity(target.value.trim());
            setCityValid(validateFormFields(target.value, "paymentForm"));
        }
        else if (target.name === 'state') {
            setStateAddr(target.value.trim());
            setStateaddrValid(validateFormFields(target.value, "stateAddress"));
        }
        else if (target.name === 'zip') {
            setZip(target.value.trim());
            setZipValid(validateFormFields(target.value, "paymentForm"));
        }
        else if (target.name === 'associateid') {
            setAssociateId(target.value.trim());
            setAssociateidValid(validateFormFields(target.value, "paymentForm"));
        }
        else if (target.name === 'employeeName') {
            setEmployeeName(target.value.trim());
            setEmployeeNameValid(validateFormFields(target.value, "paymentForm"));
        }
        else if (target.name === 'phoneNumber') {
            setPhoneNumber(target.value.trim());
            setPhoneNumberValid(validateFormFields(target.value, "custPhone"));
        }
        else if (target.name === 'parent-name-first') {
            setParentFirstName(target.value.trim());
            setParentName(target.value+' '+parentLastName);
            setParentFirstNameValid(validateFormFields(target.value, "custName"));
        }
        else if (target.name === 'parent-name-last') {
            setParentLastName(target.value.trim());
            setParentName(parentFirstName+' ' +target.value);
            setParentLastNameValid(validateFormFields(target.value, "custName"));
        }
        else if (target.name === 'other-name-first') {
            setOtherFirstName(target.value.trim());
            setOtherName(target.value+' '+otherLastName);
            setOtherFirstNameValid(validateFormFields(target.value, "custName"));
        }
        else if (target.name === 'other-name-last') {
            setOtherLastName(target.value.trim());
            setOtherName(otherFirstName + ' ' +target.value);
            setOtherLastNameValid(validateFormFields(target.value, "custName"));
        }
    }

    const handleInstValidation = (value) => {
        if(value){
            setInstrument(value);
            setInstInvalid(false);
        }else{
            setInstrument("");
            setInstInvalid(true);
        }
    }

    const priceCart = () => {
        setPriceLoading(true);
        let path = process.env.REACT_APP_ATG_HOST+'/rest/model/atg/checkout/checkoutActor/PricingEngine';

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'x-api-key': 'gPtXpHoE0HdHbAwTRpLBaF4qExAcigcG',
                'Request-Credential': 'quote$zombie!reap'
            }
        };

        let entSkuId = props.instructor.sixtyenterpriseSkuId;

        if(duration==30)
        {
            entSkuId = props.instructor.thirtyenterpriseSkuId;
        }
        let priceRequest = {
          "pricingRequest":{
             "shoppingCarts":{
                "shoppingCart":[
                   {
                      "lineItems":{
                         "lineItem":[
                            {
                               "quantity":"1",
                               "lineItemId":"1",
                               "quantityToShip":1,
                               "skuId": entSkuId
                            }
                         ]
                      },
                      "taxExempt":"false",
                      "coupons":{
                         "couponId": coupons
                      },
                      "shippingInfo":[
                         {
                            "shippingMethodInfo":{
                               "shipByCarrier":{
                                  "shippingMethod":"99",
                                  "shippingAddress":{
                                    "country":"US",
                                    "lastName":"LastName",
                                    "zipCode":"91362",
                                    "isAPOFPO":"false",
                                    "city":"Westlake Village",
                                    "companyName":"",
                                    "addressLine1":"5795 Lindero Canyon Rd",
                                    "addressLine2":"",
                                    "state":"CA",
                                    "isInternational":"false"
                                  }
                               }
                            }
                         }
                      ],
                      "storeId":"901",
                      "paymentType":[
                         {
                            "paymentMethod":{

                            },
                            "billingAddress":{
                            }
                         }
                      ]
                   }
                ]
             },
             "siteId":"7",
             "source":"POS"
          }
       };

        axios.post(path, priceRequest, axiosConfig)
        .then(res => {
            var discount=0;
            var response = res.data.pricingResponse;
            var lessonItemPrice = 0;
            var orderTotal = 0;
            if(response.errorCode)
            {
                setCouponErrorMessage(response.errorMsg);
                coupons.pop(currentCoupon);
                setPriceLoading(false);
                return;
            }
            setCouponErrorMessage('');
            orderTotal = response.orderPriceInfo.amount;
            for(var li of response.lineItems.lineItem)
            {
                if(li.skuId === '1573307')
                {
                    setRegPrice(li.itemPriceInfo.salePrice);
                }
                else
                {
                    lessonItemPrice = li.itemPriceInfo.listPrice;
                    setLessonPackPrice(lessonItemPrice);
                }
                if(li.itemPriceInfo.discounts && li.itemPriceInfo.discounts.itemDiscount && li.itemPriceInfo.discounts.itemDiscount.length>0)
                {
                    for(var disc of li.itemPriceInfo.discounts.itemDiscount)
                    {
                        discount = discount + disc.discountAmount;
                    }
                }
            }
            if(custEmail.toLowerCase().includes('@musicarts.com') || custEmail.toLowerCase().includes('@guitarcenter.com') || custEmail.toLowerCase().includes('@musiciansfriend.com'))
            {
                var empDiscount = (lessonItemPrice * 0.2).toFixed(2);
                discount = discount - empDiscount;

                orderTotal = response.orderPriceInfo.amount - empDiscount;

                setIsEmployeeOrder(true);
            }
            setDiscount(discount);
            setOrderTotal(orderTotal);
            setPriceLoading(false);
        })
        .catch(function (error) {
            console.log(error);
            setPriceLoading(false);
        });;
    }

    const handleEventClick = (event) => {
        var jsonStr = JSON.stringify(event.event, null, 2);
        var jsonObj = JSON.parse(jsonStr);
        var tzTimne = moment.tz(jsonObj.start, props.instructor.timezone);
        var utcFormatStr =moment(tzTimne).utc().format();
        var day = moment(jsonObj.start).format("dddd, MMM Do, h:mm A");
        var time = moment(jsonObj.start).format("HH:mm");
        var startDate = moment(jsonObj.start).format("MM/DD/yyyy");
        var desc = 'Lesson Every '+moment(jsonObj.start).format("dddd")+' between '+moment(jsonObj.start).format("h:mm A")+' - '+moment(jsonObj.end).format("h:mm A");
        setStartDate(startDate);
        setDesc(desc);
        setTimeSlot(day);
        setTime(time);
        setTermsAccepted(false);
        setSelWeekdayCount(getSelectedWeekdayCount(startDate));
        setFinalAction("ADD TO CART");
        priceCart();
        setScheduleModal(false);
        setContactInstructor(props.instructor);
        setUtcFormatStartTime(utcFormatStr);
        setReadableSlotSel(`${startDate}_${moment(jsonObj.start).format("hh:mm A")}-${moment(jsonObj.end).format("hh:mm A")}`);
      }

      const history = useHistory();

    const routeChange = async() =>{
//        var redirectPath = process.env.REACT_APP_ATG_HOST+'/cart';
//        var atcPostPath = `${process.env.REACT_APP_ATG_HOST}/storefrontapi/cart/lesson`;
//        var lRate = _30price;
//        var selLessonType = lessonType;
//        if(lessonType === 'in-store'){
//            selLessonType = 'inStore';
//        }
//        setSLoading(true);
//        //reset
//        setAckMsg('success');
//        if(duration == 60){
//            lRate = _60price;
//        }
//        var respData = {};
//        var vGradeLevel = gradeLevel;
//        if(studentTaking === "myself" || studentTaking === "other"){
//            vGradeLevel = 'Adult';
//        }
//        try{
//            var resp = await axios.get(process.env.REACT_APP_ATG_HOST+'/storefrontapi/lessons/types', {timeout : 3000});
//            if(resp && resp.data){
//                respData = resp.data;
//            }else{
//                respData = config.lessonTypesMapping;
//            }
//        }catch(excp){
//            console.log("ECF_INTD");
//            respData = config.lessonTypesMapping;
//        }
//        var lessonID = '';
//        if(respData && respData.length > 0){
//            for(var lData of respData){
//                if(instrument.value === lData.name){
//                    lessonID = lData.id;
//                    break;
//                }
//            }
//        }
//        var atcReqData = {
//            duration : duration,
//            lessonId : lessonID,
//            lessonRate : lRate,
//            level : props.instructor.title,
//            locationType : selLessonType,
//            storeNumber : props.instructor.storeNumber,
//            teacherEmployeeId : props.instructor.id,
//            studentName : `${firstName} ${lastName}`,
//            startDateTime : utcFormatStartTime,
//            teacherName : props.instructor.name,
//            lessonFor : studentTaking,
//            grade : vGradeLevel,
//            quantity : selWeekdayCount,
//            preferredDaysOfWeek : getDaysOfWeekStrings(vUserSelections.daysOfWeek),
//            preferredTimes : getTimesOfDayStrings(vUserSelections.timeOfDay)
//        }
//
//        var atcEvent = {
//            'event' : 'maAddToCart',
//            'data' : atcReqData
//        };
//        if(process.env.REACT_APP_WORK_ENV === 'test'){
//            console.log("In Test :: Req :: ", JSON.stringify(atcEvent, null, 2));
//        }
//        window.parent.postMessage(atcEvent, '*');

        var subsRequest = {
            'lessonOrderRequest' : {
                'creditCardNumber': cardNumberVar.replace(/ /g, ''),
                'expirationMonth': expiryVar.split(' /')[0],
                'expirationYear': '20'+expiryVar.split('/ ')[1],
                'cvv': cvcVar,
                'email': custEmail,
                'firstName': firstName,
                'lastName': lastName,
                'address1': address1,
                'address2': address2,
                'companyName': '',
                'city': city,
                'state': stateaddr,
                'postalCode': zip,
                'phoneNumber': phoneNumber,
                'country': 'US',
                'couponCodes': coupons,
                'giftCardNumber': giftCardNumber,
                'giftCardAmount': ''+giftCardAmount
            }
        };

        var student_details_params = `&first_name=${firstName}&last_name=${lastName}&cust_email=${custEmail}&lesson_for=${studentTaking}&parent_name=${parentName}&other_name=${otherName}`;
        var cc_details_params='';
        const lessonTypeByStore = STORES_ONLINE_LESSON_ONLY.indexOf(props.instructor.storeNumber) === -1 ? "in-store" : 'online';
//        var cc_details_params = `&creditCardNumber=${cardNumberVar.replace(/ /g, '')}&expirationMonth=${expiryVar.split(' /')[0]}&expirationYear=${'20'+expiryVar.split('/ ')[1]}&cvv=${cvcVar}&email=${custEmail}&firstName=${firstName}&lastName=${lastName}&address1=${address1}&address2=${address2}&companyName=&city=${city}&state=${stateaddr}&postalCode=${zip}&phoneNumber=1231231231&country=US`;
        let path = process.env.REACT_APP_ATG_HOST+'/rest/model/atg/checkout/checkoutActor/expressLessonCheckout?dcs_action=additemtocart&appointmentTime='+time+'&lessonType='+lessonTypeByStore+'&instructorInternalID='+props.instructor.instructorId+'&url_catalog_ref_id='+props.instructor.sixtyskuId+'&url_product_id='+props.instructor.sixtyproductId+'&url_quantity=1&&commerce_type=New&lesson_store_id='+props.instructor.storeNumber+'&lesson_instrument='+instrument.value+'&lesson_duration='+duration+'&is_scheduled_item=true&schedule_start_date='+startDate+'&scheduled_description='+desc+student_details_params+cc_details_params+'&forceCreateNewOrder=true&associateid='+associateid+'&employeeName='+employeeName+'&isEmployeeOrder='+isEmployeeOrder+'&samCustomerStatus=AS';
        if(duration==30)
        {
          path = process.env.REACT_APP_ATG_HOST+'/rest/model/atg/checkout/checkoutActor/expressLessonCheckout?dcs_action=additemtocart&appointmentTime='+time+'&lessonType='+lessonTypeByStore+'&instructorInternalID='+props.instructor.instructorId+'&url_catalog_ref_id='+props.instructor.thirtyskuId+'&url_product_id='+props.instructor.thirtyproductId+'&url_quantity=1&&commerce_type=New&lesson_store_id='+props.instructor.storeNumber+'&lesson_instrument='+instrument.value+'&lesson_duration='+duration+'&is_scheduled_item=true&schedule_start_date='+startDate+'&scheduled_description='+desc+student_details_params+cc_details_params+'&forceCreateNewOrder=true&associateid='+associateid+'&employeeName='+employeeName+'&isEmployeeOrder='+isEmployeeOrder+'&samCustomerStatus=AS';
        }

        let requestConfig = {
          headers: {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: subsRequest
          }
        };

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'x-api-key': 'gPtXpHoE0HdHbAwTRpLBaF4qExAcigcG',
                'Request-Credential': 'quote$zombie!reap'
            }
        };

        axios.post(path, subsRequest, axiosConfig)
        .then(res => {
            if(res.data.lessonOrderResponse && res.data.lessonOrderResponse.success)
            {
                setFinalAction("paymentSuccess");
                coupons.length=0;
                setCoupons([]);
                setCurrentCoupon('');
                setOrderNo(res.data.lessonOrderResponse.orderId);

                // Send Order Confirmation Email
                var lessonTypeString = 'Online';

                if(lessonType==='in-store')
                {
                    lessonTypeString = 'In Store';
                }
                let axiosConfigEmail = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        "x-api-key": emailAPIKey
                    }
                  };
                  var reqDataEmail = {
                        "orderId": res.data.lessonOrderResponse.orderId,
                        "orderTotal": "$"+orderTotal.toFixed(2),
                        "lessonDisplayName": duration+" Minute "+instrument.value+" Lessons with "+props.instructor.name,
                        "addnAttributes": "Starting at "+time+" on "+startDate,
                        "lessonUnitPrice": "$"+lessonPackPrice.toFixed(2),
                        "orderSubTotal": "$"+(orderTotal+(discount*-1)).toFixed(2),
                        "discounts": "$"+(discount*-1).toFixed(2),
                        "orderConfirmation":true,
                        "lessonType": lessonTypeString,
                        "emailAddress":custEmail
                  }
                  axios.post(emailAPIUrl, reqDataEmail, axiosConfigEmail)
                        .then(res => {
                          setSpinner(false);
                }).catch(function (error) {
                  setSpinner(false);
                })
            }
            else
            {
                if(res.data.lessonOrderResponse.error)
                {
                    setErrorMessage(res.data.lessonOrderResponse.error.replace('..Internal error',''));
                }
                else
                {
                    setErrorMessage("Please validate your input");
                }
                setSpinner(false);
            }
         
        }).catch(function (error) {
          setSpinner(false);
        });
//          window.location.href=path;
        //console.log("Request :: ", JSON.stringify(atcReqData, null, 2));
      //window.location.href=redirectPath;
    }

    const timeOfDayMap = {
        "1" : "morning",
        "2" : "afternoon",
        "3" : "evening"
    }

    const dayOfWeekMap = {
        "1" : "sunday",
        "2" : "monday",
        "3" : "tuesday",
        "4" : "wednesday",
        "5" : "thursday",
        "6" : "friday",
        "7" : "saturday",
        "sunday" : "sunday",
        "monday" : "monday",
        "tuesday" : "tuesday",
        "wednesday" : "wednesday",
        "thursday" : "thursday",
        "friday" : "friday",
        "saturday" : "saturday"
    }

    function getTimesOfDayStrings(timesOfDayIndArr){
        var result = [];
        if(timesOfDayIndArr && timesOfDayIndArr.length > 0){
            for(var ind of timesOfDayIndArr){
                result.push(timeOfDayMap[ind]);
            }
        }
        return result;
    }

    function getDaysOfWeekStrings(daysOfWeekIndArr){
        var result = [];
        if(daysOfWeekIndArr && daysOfWeekIndArr.length > 0){
            for(var ind of daysOfWeekIndArr){
                result.push(dayOfWeekMap[ind]);
            }
        }
        return result;
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                ? '#DDDDDD'
                : isFocused
                ? '#F5F5F5'
                : null,
            color: 'black',
            fontWeight: 500,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor:
                !isDisabled && (isSelected ? '#DDDDDD' : '#F5F5F5'),
            },
            };
        },
        menuPortal: styles => ({ ...styles, zIndex: 9999 })
    };

    const hasImage = props.instructor.imagename && (props.instructor.imagename !== 'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png');
    const hideInstructorName = !props.instructor.shortbio && !hasImage;
    const storeAddress = props.instructor.storeaddress2 ? `${props.instructor.storeaddress1} ${props.instructor.storeaddress2}` : props.instructor.storeaddress1;
    const instructorStore = `${props.instructor.storecity} - ${storeAddress}, ${props.instructor.storecity}, ${props.instructor.storestate} ${props.instructor.storezip}`;
    const lessonLocation = lessonType === 'in-store' ? instructorStore : 'online';

    return (
      <>
        <div className={`ldOverlay ${sLoading ? "dBlock" : "dNone"}`}>
          <div className="uil-reload-css reload-background reload-small mr-1 icLoader">
            <div />
          </div>
        </div>
        <div className="modal-header no-border-header text-center">
          <button
            className="close mdl-close"
            type="button"
            onClick={() => props.modalFn(false)}
          >
            <span>×</span>
          </button>
                  <h4 className="modal-title text-center contact-modal">Take a {instrument.value} Lesson with {hideInstructorName ? "Music & Arts Instructor" :props.instructor.name}</h4>
        </div>
                <div className="modal-body" style={{height: '100%'}}>
                <h6 style={{display: ackMsg === 'success' ? 'none': ''}} className="ackSec lpp-mb-1r">
            <span className={`subAck ackFailure`}>{atcErrorMessage}</span>
          </h6>
          <Row>
            <Col md="6" xs="12">
              <FormGroup>
                <Row className="ml-auto">
                  <Label className="font-weight-bold">Location</Label>
                </Row>
                <Row className="dur-opt">
                  <Col className="pad-r-0">
                    <FormGroup check inline>
                                        <Label className="dow-lbl" style={{fontWeight: '500'}}>
                        {lessonLocation}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    name="instrumentSelect"
                                    value={instrument}
                                    onChange={(value) => handleInstValidation(value)}
                                    options={instrumentSet}
                                    placeholder="Choose Instrument"
                                    isClearable={true}
                                    styles={colourStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition='fixed'
                                    menuPlacement='bottom'
                                    menuShouldScrollIntoView={false}
                                />
                                <Input className="input-req-ph" invalid={instInvalid} />
                                <FormFeedback>Please select an instrument to proceed</FormFeedback> */}
              </FormGroup>
            </Col>
            <Col md="3" xs="12">
              <Row className="ml-auto">
                <Label className="font-weight-bold">Duration</Label>
              </Row>
              <Row className="dur-opt">
                <Col className="pad-r-0">
                  <FormGroup check inline style={{ marginRight: 0 }}>
                                    <Label check className="dow-lbl" style={{color : finalAction === "ADD TO CART" ? 'grey': ''}}>
                      <Input
                        id="lessonDuration1"
                        name="lessonDuration"
                        type="checkbox"
                        value={"30"}
                        onChange={handleSelection}
                        checked={duration === "30"}
                        disabled={finalAction === "ADD TO CART"}
                      />
                      30 mins <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </Col>
                <Col className="pad-r-0">
                  <FormGroup check inline style={{ marginRight: 0 }}>
                                    <Label check className="dow-lbl" style={{color : finalAction === "ADD TO CART" ? 'grey': ''}}>
                      <Input
                        id="lessonDuration2"
                        name="lessonDuration"
                        type="checkbox"
                        value={"60"}
                        onChange={handleSelection}
                        checked={duration === "60"}
                        disabled={finalAction === "ADD TO CART"}
                      />
                      60 mins <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md="3" xs="12">
              <Row className="ml-auto">
                <Label className="font-weight-bold">Lesson Type</Label>
              </Row>
              <Row className="dur-opt">
                {
                STORES_ONLINE_LESSON_ONLY.indexOf(props.instructor.storeNumber) === -1 ?
                  <Col className="pad-r-0">
                    <FormGroup check inline style={{ marginRight: 0 }}>
                                    <Label check className="dow-lbl" style={{color : finalAction === "ADD TO CART" ? 'grey': ''}}>
                        <Input
                          id="lessonType1"
                          name="lessonType"
                          type="checkbox"
                          value="in-store"
                          onChange={handleSelection}
                          checked={lessonType === "in-store"}
                          disabled={finalAction === "ADD TO CART"}
                        />
                        In Store <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  </Col>
                :
                  <Col className="pad-r-0">
                    <FormGroup check inline style={{ marginRight: 0 }}>
                                        <Label check className="dow-lbl" style={{color : finalAction === "ADD TO CART" ? 'grey': ''}}>
                        <Input
                          id="lessonType2"
                          name="lessonType"
                          type="checkbox"
                          value="online"
                          onChange={handleSelection}
                                            checked={lessonType === 'online' || !(props.instructor.activityFull && (props.instructor.activityFull.includes('In-Store '+instrument.value) ||  props.instructor.activityFull.includes('In-Store '+instrument.value+' Lesson')))}
                          disabled={finalAction === "ADD TO CART"}
                        />
                        Online <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
          <hr />
          <Collapse isOpen={bookStep < 2}>
            <Row>
              <Col>
                <Button
                  className="btn-round next1-btn"
                  color="default"
                  href="!#"
                  name="cancel1"
                  onClick={(e) => {
                    e.preventDefault();
                    props.modalFn(false);
                  }}
                  disabled={bookStep > 1}
                >
                  CANCEL
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn-round next1-btn"
                                style={{float : 'right'}}
                  color="danger"
                  href="!#"
                  name="next1"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelection(e);
                  }}
                  disabled={bookStep > 1 || instInvalid}
                >
                  NEXT
                </Button>
              </Col>
            </Row>
          </Collapse>

          <Collapse isOpen={bookStep > 1}>
            <Row className="row-next2">
              <Col md="4" xs="12">
                <Row className="ml-auto">
                  <Label className="font-weight-bold">
                                        Billing Type <a href="!#"
                      onClick={(e) => e.preventDefault()}
                      id="know-more-subscription"
                      className="know-more-subs"
                    >
                      (?)
                    </a>
                  </Label>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="know-more-subscription"
                  >
                    Lesson accounts are set up on EZ pay. Your lesson
                    subscription authorizes Music & Arts lessons to effect
                    payment for approved monthly fees and charges for the
                    duration of monthly lessons through credit card automatic
                    billing.
                  </UncontrolledTooltip>
                </Row>
                <Row className="dur-opt">
                  {/* <Col className="pad-r-0">
                                        <FormGroup check inline>
                                        <Label check className="dow-lbl">
                                            <Input
                                            id="lessonCount1"
                                            name="lessonCount"
                                            type="checkbox"
                                            value="1"
                                            onChange={handleSelection}
                                            checked={count <= 1}
                                            />
                                            1 Lesson <span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                    </Col>
                                    <Col className="pad-r-0">
                                        <FormGroup check inline>
                                        <Label check className="dow-lbl">
                                            <Input
                                            id="lessonCount2"
                                            name="lessonCount"
                                            type="checkbox"
                                            value="4"
                                            onChange={handleSelection}
                                            checked={count > 1}
                                            />
                                            Subscribe <span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                    </Col> */}
                  <Col className="pad-r-0">
                    <FormGroup check inline>
                      <Label className="dow-lbl subs-ind">Subscription</Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="4" xs="12">
                <Row className="ml-auto">
                  <Label className="font-weight-bold">Schedule Lesson</Label>
                </Row>
                <Row className="dur-opt">
                                {allowToSchedule && allowToSchedule === true ?
                                    <Col className="pad-r-0" style={{color : finalAction === "ADD TO CART" ? 'grey': ''}}>
                      <FormGroup check inline>
                        <Label check className="dow-lbl">
                          <Input
                            id="scheduleNow1"
                            name="scheduleNow"
                            type="checkbox"
                            value="yes"
                            onChange={handleSelection}
                            checked={scheduleNow === "yes"}
                            disabled={finalAction === "ADD TO CART"}
                          />
                          Yes <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                    </Col>
                                    :
                    <></>
                                    }
                                    {studentTaking === "enableSchedulelater" ?
                    <Col className="pad-r-0">
                      <FormGroup check inline>
                                        <Label check className="dow-lbl" style={{color : finalAction === "ADD TO CART" ? 'grey': ''}}>
                          <Input
                            id="scheduleNow2"
                            name="scheduleNow"
                            type="checkbox"
                            value="later"
                            onChange={handleSelection}
                            checked={scheduleNow === "later"}
                            disabled={finalAction === "ADD TO CART"}
                          />
                          Later <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                                    </Col> : <></>}
                </Row>
              </Col>
              <Col md="4" xs="12">
                <Row className="ml-auto">
                  <Label className="font-weight-bold">Change Instrument</Label>
                </Row>
                <Row className="dur-opt">
                  <Col className="pad-r-0">
                    <FormGroup check inline>
                                        <Dropdown isOpen={dropDownOpen} toggle={ddtoggle} color="danger" style={{ color: '#dc3545 !important', background: '#dc3545 !important' }}>
                        <DropdownToggle
                          caret
                          style={{ background: "#dc3545 !important" }}
                        >
                          {instrument.value}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ background: "#dc3545 !important" }}
                        >
                          {props.instructor.instruments.map(
                            (instrumentRec, key) => (
                              <DropdownItem
                                value={instrumentRec}
                                onClick={(e) => {
                                  changeInstrument(e);
                                }}
                              >
                                {instrumentRec}
                              </DropdownItem>
                            )
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs="12" md="12">
                <h4 className="booking-student-section">Student Information</h4>
              </Col>
              <Col md="6" xs="12">
                <FormGroup>
                  <Row className="ml-auto">
                    <Label className="font-weight-bold bnReqField">
                      First Name
                    </Label>
                  </Row>
                                    <Input className="input" type="text" name="first-name" onBlur={(e) => setFirstNameValid(validateFormFields(e.target.value, "custName"))}  onChange={handleSelection} invalid={firstNameValid === 'invalid'}/>
                  <FormFeedback>Please enter a first name</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6" xs="12">
                <FormGroup>
                  <Row className="ml-auto">
                    <Label className="font-weight-bold bnReqField">
                      Last Name
                    </Label>
                  </Row>
                                    <Input className="input" type="text" name="last-name" onBlur={(e) => setLastNameValid(validateFormFields(e.target.value, "custName"))} onChange={handleSelection} invalid={lastNameValid === 'invalid'}/>
                  <FormFeedback>Please enter a last name</FormFeedback>
                </FormGroup>
              </Col>
                            {studentTaking === "hidethisinput" ? <Col md="12" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">
                        Email
                      </Label>
                    </Row>
                                    <Input className="input" type="text" name="cust-email" onBlur={(e) => setEmailValid(validateFormFields(e.target.value, "custEmail"))} onChange={handleSelection} invalid={emailValid === 'invalid'}/>
                    <FormFeedback>Please enter a valid email</FormFeedback>
                  </FormGroup>
                            </Col> : ''}
              <Col md="12" xs="12">
                <Row className="ml-auto">
                  <Label className="font-weight-bold">
                    Who is taking lessons?
                  </Label>
                </Row>
                <Row className="dur-opt">
                  <Col className="pad-r-0">
                    <FormGroup check inline>
                      <Label check className="dow-lbl">
                        <Input
                          id="student-taking1"
                          name="student-taking"
                          type="checkbox"
                          value="myself"
                          onChange={handleSelection}
                          checked={studentTaking === "myself"}
                        />
                        Myself <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col className="pad-r-0">
                    <FormGroup check inline>
                      <Label check className="dow-lbl">
                        <Input
                          id="student-taking2"
                          name="student-taking"
                          type="checkbox"
                          value="other"
                          onChange={handleSelection}
                          checked={studentTaking === "other"}
                        />
                        Other <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col className="pad-r-0">
                    <FormGroup check inline>
                      <Label check className="dow-lbl">
                        <Input
                          id="student-taking3"
                          name="student-taking"
                          type="checkbox"
                          value="child"
                          onChange={handleSelection}
                          checked={studentTaking === "child"}
                        />
                        Child <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  </Col>
                                    {studentTaking === "child" ?
                    <Col md="12" xs="12" style={{ paddingTop: 10 }}>
                      <FormGroup>
                        <Row className="ml-auto">
                          <Label className="font-weight-bold bnReqField">
                            Parent Email
                          </Label>
                        </Row>
                                            <Input className="input" type="text" name="cust-email" onBlur={(e) => setEmailValid(validateFormFields(e.target.value, "custEmail"))} onChange={handleSelection} invalid={emailValid === 'invalid'}/>
                                <FormFeedback>Please enter a valid email</FormFeedback>
                      </FormGroup>
                                    </Col> :
                    <Col md="12" xs="12" style={{ paddingTop: 10 }}>
                      <FormGroup>
                        <Row className="ml-auto">
                          <Label className="font-weight-bold bnReqField">
                            Email
                          </Label>
                        </Row>
                                             <Input className="input" type="text" name="cust-email" onBlur={(e) => setEmailValid(validateFormFields(e.target.value, "custEmail"))} onChange={handleSelection} invalid={emailValid === 'invalid'}/>
                        <FormFeedback>Please enter a valid email</FormFeedback>
                      </FormGroup>
                                     </Col>}
                                    {studentTaking === "child" ? <Col md="6" xs="12">
                      <FormGroup>
                        <Row className="ml-auto">
                          <Label className="font-weight-bold bnReqField">
                            Parent First Name
                          </Label>
                        </Row>
                                            <Input className="input" type="text" name="parent-name-first" onBlur={(e) => setParentFirstNameValid(validateFormFields(e.target.value, "custName"))} onChange={handleSelection} invalid={parentFirstNameValid === 'invalid'}/>
                        <FormFeedback>
                          Please enter a valid Parent First Name
                        </FormFeedback>
                      </FormGroup>
                                    </Col> : '' }
                                    {studentTaking === "child" ? <Col md="6" xs="12">
                      <FormGroup>
                        <Row className="ml-auto">
                          <Label className="font-weight-bold bnReqField">
                            Parent Last Name
                          </Label>
                        </Row>
                                            <Input className="input" type="text" name="parent-name-last" onBlur={(e) => setParentLastNameValid(validateFormFields(e.target.value, "custName"))} onChange={handleSelection} invalid={parentLastNameValid === 'invalid'}/>
                        <FormFeedback>
                          Please enter a valid Parent Last Name
                        </FormFeedback>
                      </FormGroup>
                                    </Col> : '' }
                                    {studentTaking === "other" ? <Col md="6" xs="12">
                      <FormGroup>
                        <Row className="ml-auto">
                          <Label className="font-weight-bold bnReqField">
                            Billing First Name
                          </Label>
                        </Row>
                                            <Input className="input" type="text" name="other-name-first" onBlur={(e) => setOtherFirstNameValid(validateFormFields(e.target.value, "custName"))} onChange={handleSelection} invalid={otherFirstNameValid === 'invalid'}/>
                        <FormFeedback>
                          Please enter a valid Billing First Name
                        </FormFeedback>
                      </FormGroup>
                                    </Col> : '' }
                                    {studentTaking === "other" ? <Col md="6" xs="12">
                      <FormGroup>
                        <Row className="ml-auto">
                          <Label className="font-weight-bold bnReqField">
                            Billing Last Name
                          </Label>
                        </Row>
                                            <Input className="input" type="text" name="other-name-last" onBlur={(e) => setOtherLastNameValid(validateFormFields(e.target.value, "custName"))} onChange={handleSelection} invalid={otherLastNameValid === 'invalid'}/>
                        <FormFeedback>
                          Please enter a valid Billing Last Name
                        </FormFeedback>
                      </FormGroup>
                                    </Col> : '' }
                                    {studentTaking === "child" ? <Col md="6" xs="12">
                      <FormGroup>
                        <Row className="ml-auto">
                          <Label className="font-weight-bold bnReqField">
                            Student Grade
                          </Label>
                        </Row>
                        <Input
                          type="select"
                          name="grade-level"
                          value={gradeLevel}
                          onChange={handleSelection}
                        >
                          <option value="">Select</option>
                          <option>Pre-Kindergarten</option>
                          <option>Kindergarten</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>Adult</option>
                        </Input>
                        <FormFeedback>Please select a grade</FormFeedback>
                      </FormGroup>
                                </Col> : null }
                </Row>
              </Col>
            </Row>
            <hr />
                    {priceLoading ?
              <>
                        <Loader type="spinner-default" bgColor={"#66615B"} color={'#66615B'} size={75} />
                    </> : <Container>
                <Row>
                  <Col md="6" xs="9" className="my-auto">
                    <div className="desc-ct">
                      <span>
                        {`${duration} min ${count > 1 ? "weekly" : ""} ${
                          instrument.value
                        } ${count > 1 ? "lessons" : "lesson"}
                                    with ${
                                      hideInstructorName
                                        ? "Music & Arts Instructor"
                                        : props.instructor.name
                                    } `}
                      </span>
                                <a href="!#" onClick={(e) => {e.preventDefault();setScheduleModal(true)}} className="know-more-subs">
                                    {`${timeSlot && scheduleNow === 'yes' ? (count > 1 ? "starting on " : "on ")+timeSlot : ""}`}
                      </a>
                    </div>
                  </Col>
                  <Col md="6" xs="3">
                    <Row>
                      <div className="sess-price">
                        <Label className="font-weight-bold">Weekly Price</Label>
                        <span>{`$${price}`}</span>
                      </div>
                    </Row>
                  </Col>
                </Row>
                    {orderTotal!='' ?
                  <Row>
                    <Col md="6" xs="9" className="my-auto">
                      <div className="desc-ct">
                        <span>Registration Fee (One Time Charge)</span>
                      </div>
                    </Col>
                    <Col md="6" xs="3">
                      <Row>
                        <div
                          className="sess-price"
                          style={{ paddingRight: 60 }}
                        >
                          <span style={{ fontSize: 20 }}>{`$${regPrice}`}</span>
                        </div>
                      </Row>
                    </Col>
                    </Row> : '' }
                    {orderTotal!='' ?
                  <Row>
                    <Col md="6" xs="9" className="my-auto">
                      <div className="desc-ct">
                        <span>
                                    Discounts {discount<0 && coupons.length>0 ? <a style={{color:'#dc3545'}} onClick={(e) => {e.preventDefault();removeCoupon(e);}} > (Click here to remove) </a> : ''}
                        </span>
                      </div>
                    </Col>
                    <Col md="6" xs="3">
                      <Row>
                        <div
                          className="sess-price"
                          style={{ paddingRight: 60 }}
                        >
                                {discount<0 ? <span style={{fontSize:20, color:'#dc3545'}}>{`$${discount}`}</span> : <span style={{fontSize:20}}>{`$${discount}`}</span> }
                        </div>
                      </Row>
                    </Col>
                    </Row> : ''}
                    {orderTotal!='' ? <Row>
                    <Col md="6" xs="9" className="my-auto">
                      <div className="desc-ct">
                                <span style={{fontWeight: 'bold'}}>
                          Order Total (Due Today)
                        </span>
                      </div>
                    </Col>
                    <Col md="6" xs="3">
                      <Row>
                        <div
                          className="sess-price"
                          style={{ paddingRight: 60 }}
                        >
                                <span style={{fontSize:20, color:'#dc3545'}}>{`$${orderTotal}`}</span>
                        </div>
                      </Row>
                    </Col>
                    </Row> : <></>}
                    {orderTotal!='' && finalAction === "ADD TO CART" ?
                  <InputGroup style={{ marginTop: 10 }}>
                        {couponErrorMessage!=''? <Col md="12" xs="12"><Alert color="danger" style={{backgroundColor: '#dc3545', fontWeight:'bold', fontSize: '13px'}}>
                        {couponErrorMessage} </Alert></Col>: null}
                    <Input
                      className="input"
                      type="text"
                      name="coupon"
                      onChange={handleCouponChange}
                      value={currentCoupon}
                    />
                    <InputGroupAddon addonType="append">
                          <Button style={{color:'#dc3545'}} onClick={(e) => {e.preventDefault();applyCoupon(e);}}>Apply Coupon</Button>
                    </InputGroupAddon>
                  </InputGroup>
                    : '' }
                    </Container>}
                        {priceAlertMsg ?
              <Row>
                <Col>
                  <span className="s-span-lbl-alert">
                    <Label className="s-label-alert">*</Label>There is an
                    upcoming 5% price increase to lessons starting 2/28/2022.
                    This will be reflected on your next monthly payment.
                  </span>
                </Col>
              </Row>
                        :
                        <></>}

            <hr />
                        {finalAction === "ADD TO CART" ? <Row id="paymentSection" name="paymentSection">
                        {errorMessage!=''? <Col md="12" xs="12"><Alert color="danger" style={{backgroundColor: '#dc3545', fontWeight:'bold', fontSize: '13px'}}>
                      {errorMessage}
                          </Alert></Col>: null}
                <Col md="12" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <PaymentInputsWrapper
                        {...wrapperProps}
                        styles={customStyles}
                      >
                        <svg {...getCardImageProps({ images })} />
                        <input
                          {...getCardNumberProps({ onChange: handleSelection })}
                        />
                        <input
                          {...getExpiryDateProps({ onChange: handleSelection })}
                        />
                        <input
                          {...getCVCProps({ onChange: handleSelection })}
                        />
                      </PaymentInputsWrapper>
                    </Row>
                                <div class="invalid-feedback" style={{display:'block'}}>{((meta.touchedInputs.cardNumber && meta.erroredInputs.cardNumber!=null) || (meta.touchedInputs.expiryDate && meta.erroredInputs.expiryDate!=null) || (meta.touchedInputs.cvc && meta.erroredInputs.cvc!=null)) ? <span>{meta.error}</span> : ''}</div>
                  </FormGroup>
                </Col>
                <Col md="9" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">
                        Address
                      </Label>
                    </Row>
                                <Input className="input" type="text" name="address-line1" onBlur={(e) => setAddress1Valid(validateFormFields(e.target.value, "paymentForm"))}  onChange={handleSelection} invalid={address1Valid === 'invalid'}/>
                    <FormFeedback>Please enter billing address</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="3" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold">Apt#</Label>
                    </Row>
                                <Input className="input" type="text" name="address-line2" onBlur={(e) => setAddress2Valid(true)} onChange={handleSelection} invalid={address2Valid === 'invalid'}/>
                    <FormFeedback>Please enter apt#</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">
                        City
                      </Label>
                    </Row>
                                <Input className="input" type="text" name="city" onBlur={(e) => setCityValid(validateFormFields(e.target.value, "paymentForm"))} onChange={handleSelection} invalid={cityValid === 'invalid'}/>
                    <FormFeedback>Please enter city</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">
                        State
                      </Label>
                    </Row>
                                <Input className="input" type="text" name="state" onBlur={(e) => setStateaddrValid(validateFormFields(e.target.value, "stateAddress"))} onChange={handleSelection} invalid={stateaddrValid === 'invalid'}/>
                    <FormFeedback>
                      Please enter a valid Two Letter State code
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">Zip</Label>
                    </Row>
                                <Input className="input" type="text" name="zip" onBlur={(e) => setZipValid(validateFormFields(e.target.value, "paymentForm"))} onChange={handleSelection} invalid={zipValid === 'invalid'}/>
                    <FormFeedback>Please enter zip</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">
                        Phone Number
                      </Label>
                    </Row>
                                <Input className="input" type="text" name="phoneNumber" onBlur={(e) => setPhoneNumberValid(validateFormFields(e.target.value, "custPhone"))} onChange={handleSelection} invalid={phoneNumberValid === 'invalid'}/>
                    <FormFeedback>Please enter Phone Number</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">
                        Associate Id
                      </Label>
                    </Row>
                                <Input className="input" type="text" name="associateid" onBlur={(e) => setAssociateidValid(validateFormFields(e.target.value, "paymentForm"))} onChange={handleSelection} invalid={associateidValid === 'invalid'}/>
                    <FormFeedback>Please enter Associate Id</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Row className="ml-auto">
                      <Label className="font-weight-bold bnReqField">
                        Associate Name
                      </Label>
                    </Row>
                                <Input className="input" type="text" name="employeeName" onBlur={(e) => setEmployeeNameValid(validateFormFields(e.target.value, "paymentForm"))} onChange={handleSelection} invalid={employeeNameValid === 'invalid'}/>
                    <FormFeedback>Please enter Associate Name</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12">
                  <FormGroup>
                    <InputGroup style={{ marginTop: 10 }}>
                      {giftCardBalance != "" ? (
                        !(isNumber(giftCardBalance) && giftCardBalance > 0) ? (
                          <Col md="12" xs="12">
                            <Alert
                              color="danger"
                              style={{
                                backgroundColor: "#dc3545",
                                fontWeight: "bold",
                                fontSize: "13px",
                              }}
                            >
                              {giftCardBalance}
                            </Alert>
                          </Col>
                        ) : (
                          <Col md="12" xs="12">
                            <Alert
                              color="success"
                              style={{
                                backgroundColor: "green",
                                fontWeight: "bold",
                                fontSize: "13px",
                              }}
                            >
                              Current Gift Card Balance : ${giftCardBalance}{" "}
                              <a
                                style={{ fontWeight: "bold" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeGiftCard(e);
                                }}
                              >
                                {" "}
                                (Click here to remove){" "}
                              </a>
                            </Alert>
                          </Col>
                        )
                      ) : (
                        ""
                      )}
                      <Input
                        placeholder="Enter Gift Card Number"
                        className="input"
                        type="text"
                        name="coupon"
                        onChange={handleGiftCardChange}
                        value={giftCardNumber}
                      />
                      <InputGroupAddon addonType="append">
                                          <Button style={{color:'#dc3545'}} onClick={(e) => {e.preventDefault();checkGiftCardBalance(e);}}>Check & Apply Balance</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col xs="12">
                  <FormGroup check inline>
                    <Label check className="dow-lbl">
                      <Input
                        id="termsAccepted"
                        name="termsAccepted"
                        type="checkbox"
                        className="terms-accpt-chk"
                        onChange={handleSelection}
                        checked={termsAccepted}
                                            style={{margin: '18px -15px', backgroundColor: 'red'}}
                                            /> <span className="form-check-sign" />
                            <p style={{textAlign: 'center', margin: '2px 0', fontSize: '14px'}}>I accept Music & Arts Lessons <a href="#" className="t-c-btn priv-policy-link" onClick={(e) => {e.preventDefault(); setTermsModal(true)}}>Terms & Conditions.</a></p>
                    </Label>
                  </FormGroup>

                            </Col></Row> : finalAction=== 'paymentSuccess'? <Col md="12" xs="12"><Alert color="success" style={{fontWeight:'bold', fontSize: '13px'}}>
                  Thank you for your Subscription. Order # {orderNo}
                                                                                                      </Alert></Col>: null}
                        {finalAction!= 'paymentSuccess' ?
              <Collapse isOpen={bookStep >= 2}>
                <Row>
                  <Col md="6" xs="4">
                    <Button
                      className="btn-round next1-btn"
                      color="default"
                      href="!#"
                      name="cancel1"
                      onClick={(e) => {
                        e.preventDefault();
                        props.modalFn(false);
                      }}
                      disabled={spinner}
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col md="6" xs="8">
                    <Button
                      className="btn-round next1-btn"
                                    style={{float : 'right'}}
                      color="danger"
                      href="!#"
                      name={finalAction === "SCHEDULE" ? "schdl" : "next2"}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelection(e);
                      }}
                      disabled={instInvalid || !formValid || spinner}
                    >
                                    {(finalAction==='ADD TO CART' || finalAction==='paymentError'||finalAction==='paymentSuccess')?'COMPLETE PURCHASE ':finalAction}
                                    {spinner? <Spinner animation="border" role="status" size="sm">
                                     </Spinner> : null}
                    </Button>
                  </Col>
                </Row>
                        </Collapse> : null}
          </Collapse>
          <Modal
            isOpen={scheduleModal}
            toggle={() => setScheduleModal(false)}
            className="modal-schd-now"
          >
            <div className="modal-header no-border-header text-center">
              <button
                className="close mdl-close"
                type="button"
                onClick={() => setScheduleModal(false)}
              >
                <span>×</span>
              </button>
              <h3 className="modal-title text-center contact-modal">
                Schedule
              </h3>
                                <h6 className="modal-title text-center contact-modal">All times in {props.instructor.timezone ? moment.tz.zone(props.instructor.timezone).abbr(new Date()) : 'Store Timezone'}</h6>
            </div>
                            <div className="modal-body" id="schedule-modal-body" style={{height: '700px', maxHeight: '750px'}}>
              <div className="schedule-legend">
                <p>Available</p>
              </div>
              <div>
                <CustomCalendar
                  view="timeGridWeek"
                  handleEventClick={handleEventClick}
                  instructor={contactInstructor}
                  duration={duration}
                  lessonType={lessonType}
                  storesOnlineLessonOnly={STORES_ONLINE_LESSON_ONLY}
                />
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={termsModal}
                        toggle={() => {window.parent.postMessage(termsModalEvent, '*'); setTermsModal(false)}}
            className="modal-schd-now"
          >
            <div className="modal-header no-border-header text-center">
              <button
                className="close mdl-close"
                type="button"
                                    onClick={() => {window.parent.postMessage(termsModalEvent, '*'); setTermsModal(false)}}
              >
                <span>×</span>
              </button>
              <h3 className="modal-title text-center contact-modal">
                Lessons Terms & Conditions
              </h3>
            </div>
                            <div className="modal-body" style={{height: '700px', maxHeight: '750px'}}>
                                <div className="terms-modal">
                                    <TermsModal />
                                </div>
                            </div>

                        </Modal>

                        <Modal size="sm" isOpen={timeUnavailable} className="time-unavailable-modal">
                            <div className="modal-body">
                                <p>
                                    <strong>
                                        Your selected time slot is not available. Please select a different time for your lessons.
                                        <br/>
                                        <br/>
                                    </strong>
                                </p>
                                <Button color="primary" className="float-right" size="normal" onClick={() => setTimeUnavailable(false)}>
                                    Close
                                </Button>
                            </div>
                        </Modal>
                </div>
        </>
    )
}

export default BookNowModal;
