import React, {  useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import useWindowDimensions from '../../gc-comps/utility/useWindowDimensions.js';
var moment = require('moment');
var momentTZ = require('moment-timezone');

export default function CustomCalendar(props) {
    const {view, ...others} = props;
    const { height, width } = useWindowDimensions();

    React.useEffect(() => {
        const elements = document.getElementsByClassName('fc-header-toolbar');
        elements[0].classList.add('row');

        const cntnElements = document.getElementsByClassName('fc-view-harness');
        cntnElements[0].classList.add('row', 'mob-container-ht');

        const subElements = document.getElementsByClassName('fc-toolbar-chunk');
        for(var subEl of subElements){
            subEl.classList.add('col-12', 'col-md-4', 'fc-toolbar-schd');
        }

        const todayBtn = document.getElementsByClassName('fc-today-button');
        todayBtn[0].innerHTML = 'this week';
    }, []);

    const calendarRef = useRef();

    const getTimeDiffInMins = (timeSlots) => {
        var momentStart = moment(timeSlots[0], "HH:mm");
        var momentEnd = moment(timeSlots[1], "HH:mm");
        var diff = momentEnd.diff(momentStart, 'minute');
        return diff;
    }
    var tempUniqueAvailability = [...new Set(props.instructor.availability)];
    var uniqueAvailability = [];

    for(var tempAvl of tempUniqueAvailability){
        if(tempAvl.endsWith('#BOTH') || props.storesOnlineLessonOnly.indexOf(props.instructor.storeNumber) > -1){
            uniqueAvailability.push(tempAvl);
        }else if(tempAvl.endsWith('#INSTORE') && props.lessonType === 'in-store'){
            uniqueAvailability.push(tempAvl);
        }else if(tempAvl.endsWith('#ONLINE') && props.lessonType === 'online'){
            uniqueAvailability.push(tempAvl);
        }
    }

    var vSelectedDays = [];
    if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).daysOfWeek)
    {
        vSelectedDays = JSON.parse(localStorage.getItem('userSelections')).daysOfWeek;
        vSelectedDays = getDayIndicators(vSelectedDays);
        //console.log("vSelectedDays :: ", vSelectedDays);
    }

    function getDayIndicators(daysSelected){

        var result = [];

        for(var val of daysSelected){
            var dayEq = 0;
            if(val=='monday' || val=='2')
            {
                dayEq = 2;
            }
            else if(val=='tuesday' || val=='3')
            {
                dayEq = 3;
            }
            else if(val=='wednesday' || val=='4')
            {
                dayEq = 4;
            }
            else if(val=='thursday' || val=='5')
            {
                dayEq = 5;
            }
            else if(val=='friday' || val=='6')
            {
                dayEq = 6;
            }
            else if(val=='saturday' || val=='7')
            {
                dayEq = 7;
            }
            else if(val=='sunday' || val=='1')
            {
                dayEq = 1;
            }
            result.push(dayEq);
        }

        return result;

      }

    var startDate = new Date();
    var vStartTimeToScroll = '';
    if(uniqueAvailability.length > 0){
        startDate = uniqueAvailability[0].split(';')[0].split('T')[0];
        vStartTimeToScroll = uniqueAvailability[0].split(';')[0].split('T')[1];
        const instructorTimezone = momentTZ().tz(props.instructor.timezone)
        if(instructorTimezone.isAfter(moment(startDate))){
            //first slot from timeslots is in the past
            //find the next future slot
            for(var uqAvl of uniqueAvailability){
                var tempStart = uqAvl.split(';')[0].split('T')[0];
                var tempStartFull = uqAvl.split(';')[0];
                var tempDayOfWeek = moment(tempStart).day()+1;
                tempDayOfWeek = ''+tempDayOfWeek;
                const tempStartFullToInstructorTimezone = momentTZ(tempStartFull).tz(props.instructor.timezone)
                if(instructorTimezone.isBefore(tempStartFullToInstructorTimezone)){
                    if(vSelectedDays && vSelectedDays.length > 0){
                        if(vSelectedDays.includes(tempDayOfWeek)){
                            startDate = tempStart;
                            vStartTimeToScroll = uqAvl.split(';')[0].split('T')[1];
                            break;
                        }
                    }else{
                        startDate = tempStart;
                        vStartTimeToScroll = uqAvl.split(';')[0].split('T')[1];
                        break;
                    }

                }
                else
                {
                    uniqueAvailability = uniqueAvailability.slice(uniqueAvailability.indexOf(uqAvl)+1, uniqueAvailability.length);
                }
            }
        }else{
            for(var uqAvl of uniqueAvailability){
                var tempStart = uqAvl.split(';')[0].split('T')[0];
                var tempDayOfWeek = moment(tempStart).day()+1;
                //tempDayOfWeek = ''+tempDayOfWeek;
                //console.log(tempStart, tempDayOfWeek);
                if(vSelectedDays && vSelectedDays.length > 0){
                    if(vSelectedDays.includes(tempDayOfWeek)){
                        startDate = tempStart;
                        vStartTimeToScroll = uqAvl.split(';')[0].split('T')[1];
                        //console.log("Match found :: ", tempStart, tempDayOfWeek, vStartTimeToScroll);
                        break;
                    }
                }else{
                    startDate = tempStart;
                    vStartTimeToScroll = uqAvl.split(';')[0].split('T')[1];
                    break;
                }

            }
        }
    }
    var endDateMoment = moment();
    (localStorage.getItem('storeNumber') === '8901') ?
    endDateMoment.add(3.5, 'M') :  endDateMoment.add(17, 'd');
    var vEndDt = endDateMoment.format('YYYY-MM-DD');

    if(props.duration === '60'){
        const slotAvl = {};

        uniqueAvailability.map((value, index) => {

            var slots = value.split(';');
            var slotStart = slots[0].split('T');
            var slotEnd = slots[1].split('#')[0].split('T');

            var iSlots = slotStart[1]+"-"+slotEnd[1];
            if(!slotAvl[slotStart[0]]){
                slotAvl[slotStart[0]] = [];
            }
            slotAvl[slotStart[0]].push(iSlots);
        });

        //console.log(slotAvl);

        const slotAvlUpdated = {};
        var availability = [];

        Object.keys(slotAvl).map((day) => {
            var cSlots = slotAvl[day];
            var resultSlots = [];
            var inSlot = null;
            //identify slots starting exactly on the hour mark
            //match with next slot for continuity and merge
            //retain unmatched 30-min slots - for post processing
            for(var sl of cSlots){
                var splitSlot = sl.split('-');
                if(inSlot){
                    if(inSlot[1] === splitSlot[0] && splitSlot[1].endsWith('00')){
                        //previous end matches with current start
                        var newSlot =  inSlot[0]+"-"+splitSlot[1];
                        resultSlots.push(newSlot);
                        inSlot = null;
                    }else{
                        //no match with next slot - push the original slot
                        var orgSlot =  inSlot[0]+"-"+inSlot[1];
                        resultSlots.push(orgSlot);
                        //check and re-initialize the start slot
                        if(splitSlot[0].endsWith('00')){
                            //hour start
                            inSlot = splitSlot;
                        }else{
                            //half-hour mark
                            resultSlots.push(sl);
                            inSlot = null;
                        }
                    }
                }else{
                    if(splitSlot[0].endsWith('00')){
                        //hour start
                        inSlot = splitSlot;
                    }else{
                        //half-hour mark
                        resultSlots.push(sl);
                    }
                }
            }
            if(inSlot){
                //last entry
                var iOrgSlot =  inSlot[0]+"-"+inSlot[1];
                resultSlots.push(iOrgSlot);
            }
            slotAvlUpdated[day] = resultSlots;

            var index = 0;
            var halfHrSlot = null;
            for(var resSlot of resultSlots){
                var fSlots = resSlot.split('-');
                var diff = getTimeDiffInMins(fSlots);
                //console.log("diff ", fSlots, diff);
                index++;
                if(diff === 60){
                    availability.push({
                        id: day+"_"+index,
                        title:'',
                        start:day+"T"+fSlots[0],
                        end:day+"T"+fSlots[1],
                    });
                }else{
                    //post-prcessing check to merge slots starting at 30-min mark of the hour
                    //check for continuous slots and merge
                    //skip any un-mergeable/standalone 30-min slots
                    if(halfHrSlot){
                        if(halfHrSlot[1] === fSlots[0] && fSlots[1].endsWith('30')){
                            var newSlots =  [halfHrSlot[0], fSlots[1]];
                            var newSlotDiff = getTimeDiffInMins(newSlots);
                            halfHrSlot = null;
                            if(newSlotDiff === 60){
                                availability.push({
                                    id: day+"_"+index,
                                    title:'',
                                    start:day+"T"+newSlots[0],
                                    end:day+"T"+newSlots[1],
                                });
                            }
                        }else if(fSlots[0].endsWith('30')){
                            //re-initialize start slot
                            halfHrSlot = fSlots;
                        }
                    }else if(fSlots[0].endsWith('30')){
                        //initialize start slot
                        halfHrSlot = fSlots;
                    }
                }
            }
        });

        //console.log(slotAvlUpdated);
    }else{
        //console.log("uniqueAvailability :: ", uniqueAvailability);
        var availability = uniqueAvailability.map((value, index) => {
                return { id: index, title:'' ,start: value.split(';')[0], end: value.split(';')[1].split('#')[0] }
    //            return { id: 1, title: '', start: '2021-04-02T14:30:00', end: '2021-04-02T15:30:00' }
            }
        );
    }



    //console.log(props.duration, props.instructor.availability);

    // React.useEffect(() => {
    //     // if(width < 750){
    //     //     changeView("dayGridWeek");
    //     // }else{
    //     //     changeView("dayGridMonth");
    //     // }
    //     //changeView("dayGridWeek");
    // }, []);


    // const changeView = view => {
    //     // const API = getApi();

    //     // API && API.changeView(view);

    //     var btnElement = '';
    //     if(view === 'dayGridWeek'){
    //         const elements = document.getElementsByClassName('fc-timeGridWeek-button');
    //         btnElement = elements[0];
    //     }else{
    //         const elements = document.getElementsByClassName('fc-dayGridMonth-button');
    //         btnElement = elements[0];
    //     }
    //     btnElement.click();
    // }

    // const getApi = () => {
    //     const { current: calendarDom } = calendarRef;

    //     return calendarDom ? calendarDom.getApi() : null;
    // }

    return (
        <FullCalendar
            ref={calendarRef}
            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
            initialView={view}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'timeGridWeek,timeGridDay'
            }}
            slotMinTime="06:00:00"
            slotMaxTime="23:00:00"
            slotLabelInterval="01:00"
            dayMaxEvents={true}
            allDaySlot={false}
            displayEventEnd={true}
            initialEvents={
                availability
            }
            eventClick={props.handleEventClick}
            scrollTime={vStartTimeToScroll}
            validRange={{
                start : startDate,
                end : vEndDt
            }}
            timeZone="EDT"
            />
    );
}
