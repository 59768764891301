import React from "react";

function TermsModal() {
  return (
    <div className="terms-cndn">
      <p>
        <strong>LESSON SERVICES.</strong> This Student Enrollment Agreement sets forth the terms and conditions of your purchase of instructional services from Music & Arts (“M&A Lessons”).
      </p>
      <p>
        <strong>Registration Fee.</strong> There is a $30 non-refundable registration fee per student with this purchase.
      </p>
      <p>
        <strong>Individual Lessons.</strong> The recurring tuition fee provides students with individual weekly lessons per month. 
      </p>
      <p>
        <strong>TUITION.</strong> Tuition and registration fee are due at the time of purchase, in advance, for the following four lessons. After purchasing your first lessons package, your next subsequent lesson tuition will be billed twenty-two (22) calendar days after the date of your first lesson. Thereafter, lessons tuition is billed every four weeks. For example: A student that takes their first lesson on January 12<sup>th</sup> will be billed their next lesson tuition 22 days from January 12 (i.e., February 3) and every four-weeks starting from February 3 moving forward. Tuition does not include curriculum and materials costs. We may increase monthly fees in future months upon 30 days' written notice.
      </p>
      <p>
        Tuition fees may be refunded in full prior to the delivery of any instructional services, with written notice received within 24 hours of your initial registration date (which may be provided at the designated email address).
      </p>
      <p>
        <strong>EZ-PAY AUTHORIZATION.</strong> I hereby authorize M&A Lessons to effect payment for approved monthly fees and charges for the duration of monthly lessons through credit card automatic billing. This authorization is to remain in full effect until M&A Lessons has received written notification of cancellation (which may be provided by email at the designated email address).
      </p>
      <p>
        <strong>REGULAR ATTENDANCE</strong> is critical to the success of lessons. Please plan to attend all lessons and arrive on time and prepared.
      </p>
      <p>
        <strong>CANCELLATION/RESCHEDULE POLICY.</strong>  To cancel or reschedule a lesson at no cost, you must provide at least 24 hours prior notice. We will reschedule your lesson subject to instructor availability. If you do not show up for a lesson or if you do not provide adequate notice, you will be charged for the missed lesson. <strong>To encourage attendance and the use of lessons, all unused lessons will expire and be forfeited ninety (90) days after purchase. There will be no refunds or cash redemption available for expired unused lessons.</strong>
      </p>
      <p>
        To cancel your enrollment, and to terminate your lessons, you must provide written notice to store management, which may be provided by email (at the designated email address), prior to the upcoming billing date of the subsequent month. Termination is effective on the date of notice. In the case of enrollment cancellation, all lesson tuition fees are non-refundable, and any unpaid fees are immediately due. All unused lessons for canceled enrollment accounts will expire and be forfeited thirty (30) days from the date of notice of cancellation. There will be no refunds or cash redemption available for expired unused lessons for canceled enrollment accounts.
      </p>
      <p>
        M&A Lessons may, at its option, cancel your enrollment if you (a) do not make timely payments; (b) you fail to show up without notice for 2 consecutive lessons; (c) your conduct is rude, improper, or harmful; or (d) you have not taken a lesson or completed your registration for 90 days. 
      </p>
      <p>
        <strong>POLICIES FOR MINORS.</strong> Communications between M&A Lessons staff and with minor students should only be on site, at the facility, within M&A Lessons systems, and directly related to instruction. Any other communication should be between the adult parent/guardian of any minor student and M&A Lessons staff through M&A Lessons operated phones, M&A systems, and proper email addresses through M&A Lessons. An adult parent or guardian must accompany any student under 12 years of age upon arrival and departure from every in-store lesson.
      </p>
      <p>
        Prior to using M&A Lessons Digital Practice Support (“Level-Up”), which facilitates audio and video recording, you will be asked to provide consent for both audio and video recording.  If the student is a minor, the consent of the parent or other legal guardian will be required.   </p>
      <p>
        The recordings capture the voice and video of the student and instructor during a lesson and during practice time. The recordings will be stored in M&A Lessons Cloud service for five days from the date of instruction, provided a student or legal guardian has chosen the “Save” option at the end of each lesson. The recordings are accessible to the student, legal guardian, the instructor, and Music & Arts Management during these five days. Recordings are captured by M&A Lessons to ensure the safety of both students and instructors and improve the quality of the student’s performance and are not used or shared for any other purpose. By completing the registration, you consent to the use of Level-Up as a student or legal guardian of a student.
      </p>
      <p>
        Additionally, from time to time, M&A Lessons may record, photograph or video a student during recitals and other public performances to be used on Music & Arts websites and any other medium. By completing the registration, you consent to such use as a student or legal guardian of a student.   
      </p>
    </div>
  );
}

export default TermsModal;
