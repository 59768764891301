import React from 'react';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum'

// styles
import './assets/css/bootstrap.min.css';
import './assets/css/custom-kit.css';
import './assets/css/custom.css';
import './assets/demo/react-demo.css';
import './assets/scss/paper-kit.scss';
// pages
import { toRelativeUrl } from '@okta/okta-auth-js';
import InstructorListing from './gc-views/layout/InstructorListing';
import InstructorProfile from './gc-views/layout/InstructorProfile';
import { oktaAuth } from './oktaConfig';
import LoginPage from './views/examples/LoginPage.js';

function App() {
  if (process.env.REACT_APP_DATADOG_ENVIRONMENT === "prod") {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 30,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}
  return (
    <div className='App'>
      <BrowserRouter>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={({ oktaAuth }) => {
            window.location.replace('/login');
          }}
          restoreOriginalUri={async (oktaAuth, originalUri) => {
            window.location.replace(toRelativeUrl(originalUri || '/inst-listing', window.location.origin));
          }}
        >
          <Switch>
            <Route path='/login/callback' component={LoginCallback} />
            <Route path='/login' component={LoginPage} />
            <SecureRoute path='/inst-listing' component={InstructorListing} />
            <SecureRoute path='/instructor-profile' component={InstructorProfile} />
            <Redirect from='/' to='/inst-listing' />
          </Switch>
        </Security>
      </BrowserRouter>
    </div>
  );
}

export default App;
