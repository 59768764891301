import React from 'react';

const ECOM487EmailUrl = `https://jrf6phar18.execute-api.us-west-2.amazonaws.com/default/sg` // using the API Gateway called "sam-emails-trigger (jrf6phar18)" in ECOM-487 account
const ECOM487EmailAPIKey = "8M4ogVDXVKHSc66KCZbX9NxeLsQRPcS6zkdSLHB4" // using the API Gateway API Key from the API Gateway "sam-emails-trigger (jrf6phar18)" in ECOM-487 account
const ECOM487SearchProxyAPIUrl = "https://l8v6yk3195.execute-api.us-west-2.amazonaws.com/default/search-proxy-api" // using the API Gateway called "ma-lpp-instructors (l8v6yk3195)" in ECOM-487 account
const ECOM487SearchProxyAPIKey = "7xQ93LfngPagnwMIDnL9A78zbeMiQB5F5Xo1MKRb" // using the API Gateway API Key from the API Gateway "ma-lpp-instructors (l8v6yk3195)" in ECOM-487 account

export const emailAPIUrl = process.env.REACT_APP_SAM_BACKEND_URL && process.env.REACT_APP_SAM_BACKEND_URL !== "null" ? `${process.env.REACT_APP_SAM_BACKEND_URL}/email` : ECOM487EmailUrl,
  emailAPIKey = process.env.REACT_APP_SAM_BACKEND_API_KEY && process.env.REACT_APP_SAM_BACKEND_API_KEY !== "null" ? process.env.REACT_APP_SAM_BACKEND_API_KEY : ECOM487EmailAPIKey,
  searchProxyAPIUrl = process.env.REACT_APP_SAM_BACKEND_URL && process.env.REACT_APP_SAM_BACKEND_URL !== "null" ? `${process.env.REACT_APP_SAM_BACKEND_URL}/search-proxy-api` : ECOM487SearchProxyAPIUrl,
  searchProxyAPIKey = process.env.REACT_APP_SAM_BACKEND_API_KEY && process.env.REACT_APP_SAM_BACKEND_API_KEY !== "null" ? process.env.REACT_APP_SAM_BACKEND_API_KEY : ECOM487SearchProxyAPIKey;

export default {
    contactConsent : <span id="contactConsent" className="contactConsent">By submitting this form, I am providing consent to be contacted by Music & Arts. View our <a href="https://www.musicarts.com/cms/privacy-policy" id="priv-policy-link" className="priv-policy-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</span>,
    lessonTypesMapping :   [
                                {
                                "id": "283e393dbcdf4047b72b39a8145118df",
                                "code": "BNJO",
                                "name": "Banjo"
                                },
                                {
                                "id": "743ee571425045679827fafe01c83c04",
                                "code": "BRTN",
                                "name": "Baritone"
                                },
                                {
                                "id": "f752919f58be47759f183c6796de518f",
                                "code": "BCLR",
                                "name": "Bass Clarinet"
                                },
                                {
                                "id": "51420707d7bb4c24876b43290b9c19fa",
                                "code": "BGTR",
                                "name": "Bass Guitar"
                                },
                                {
                                "id": "59c3e96902f84d8fa314e9fbaaf3a529",
                                "code": "BSN",
                                "name": "Bassoon"
                                },
                                {
                                "id": "a45e82d4b1e440269d1776ecc3cfeea4",
                                "code": "CEL",
                                "name": "Cello"
                                },
                                {
                                "id": "0e46d117c67041b9be5a082a5c5d44a8",
                                "code": "CLR",
                                "name": "Clarinet"
                                },
                                {
                                "id": "93595befa6404b08b111bb601ee912e6",
                                "code": "PERC",
                                "name": "Drums"
                                },
                                {
                                "id": "e13433ff80314bd5859fe30654fcde81",
                                "code": "FLT",
                                "name": "Flute"
                                },
                                {
                                "id": "c2c9efab3b7847ad9fd0e9fdaa3be371",
                                "code": "FRH",
                                "name": "French Horn"
                                },
                                {
                                "id": "0ee50d5b78dc4f8ab199a9d315a80f8e",
                                "code": "GTR",
                                "name": "Guitar"
                                },
                                {
                                "id": "f9660a00b9c44e53a75f9b2719a6fe9b",
                                "code": "MND",
                                "name": "Mandolin"
                                },
                                {
                                "id": "9cfc2ba1fdb04898b76534658a63a056",
                                "code": "OBO",
                                "name": "Oboe"
                                },
                                {
                                "id": "5cdb4193e9c843869b7af337d156675c",
                                "code": "PIA",
                                "name": "Piano"
                                },
                                {
                                "id": "2859e041a41a44a295ce6cdd018399dc",
                                "code": "PIC",
                                "name": "Piccolo"
                                },
                                {
                                "id": "e0de9111a9cf43dbb90d2203edeba1f9",
                                "code": "SAX",
                                "name": "Saxophone"
                                },
                                {
                                "id": "ddca99373782430898c39b8352eb93c5",
                                "code": "STRB",
                                "name": "String Bass"
                                },
                                {
                                "id": "3f6617cff29246b59bbe58b11ef70732",
                                "code": "TECHNO",
                                "name": "Technology"
                                },
                                {
                                "id": "1170e8dd2dfd4590a4833eef4181753a",
                                "code": "THEORY",
                                "name": "Theory"
                                },
                                {
                                "id": "110b3c22997a498eba6d9b1997942c99",
                                "code": "TBN",
                                "name": "Trombone"
                                },
                                {
                                "id": "fe28aceb03c6431888a12d9f9b6b33d0",
                                "code": "TPT",
                                "name": "Trumpet"
                                },
                                {
                                "id": "75d634004fa645d2a3eaec6d9908b287",
                                "code": "TBA",
                                "name": "Tuba"
                                },
                                {
                                "id": "f993718e69e641168075440f78a1fbb9",
                                "code": "UKE",
                                "name": "Ukulele"
                                },
                                {
                                "id": "6b5b6c252e5347b4a4161139e9455e41",
                                "code": "VLA",
                                "name": "Viola"
                                },
                                {
                                "id": "0667bb8535cc47109b275ff4b4bdb371",
                                "code": "VLN",
                                "name": "Violin"
                                },
                                {
                                "id": "f300e7d96f974a08992ae218082688e0",
                                "code": "VCE",
                                "name": "Voice"
                                }
                            ],
    instrumentSynonyms : [
        {
          "name": "Bass Clarinet",
          "synonym": "Bass Clarinet"
        },
        {
          "name": "Bass Guitar",
          "synonym": "Bass"
        },
        {
          "name": "Bass Guitar",
          "synonym": "Bass Guitar"
        },
        {
          "name": "Banjo",
          "synonym": "Banjo"
        },
        {
          "name": "Baritone",
          "synonym": "Baritone"
        },
        {
          "name": "Baritone",
          "synonym": "Euphonium"
        },
        {
          "name": "Bassoon",
          "synonym": "Bassoon"
        },
        {
          "name": "Cello",
          "synonym": "Cello"
        },
        {
          "name": "Clarinet",
          "synonym": "Clarinet"
        },
        {
          "name": "Flute",
          "synonym": "Flute"
        },
        {
          "name": "French Horn",
          "synonym": "French Horn"
        },
        {
          "name": "Guitar",
          "synonym": "Acoustic Guitar"
        },
        {
          "name": "Guitar",
          "synonym": "Classical Guitar"
        },
        {
          "name": "Guitar",
          "synonym": "Electric Guitar"
        },
        {
          "name": "Guitar",
          "synonym": "Guitar"
        },
        {
          "name": "Guitar",
          "synonym": "Pedal Steel Guitar"
        },
        {
          "name": "Mandolin",
          "synonym": "Mandolin"
        },
        {
          "name": "Oboe",
          "synonym": "Oboe"
        },
        {
          "name": "Drums",
          "synonym": "Bass Drum"
        },
        {
          "name": "Drums",
          "synonym": "Bongos"
        },
        {
          "name": "Drums",
          "synonym": "Chimes"
        },
        {
          "name": "Drums",
          "synonym": "Drum Set"
        },
        {
          "name": "Drums",
          "synonym": "Drums"
        },
        {
          "name": "Drums",
          "synonym": "Mallet Percussion"
        },
        {
          "name": "Drums",
          "synonym": "Marimba"
        },
        {
          "name": "Drums",
          "synonym": "Percussion"
        },
        {
          "name": "Drums",
          "synonym": "Snare Drum"
        },
        {
          "name": "Drums",
          "synonym": "Timpani"
        },
        {
          "name": "Drums",
          "synonym": "Vibraphone"
        },
        {
          "name": "Drums",
          "synonym": "Xylophone"
        },
        {
          "name": "Piano",
          "synonym": "Keyboard"
        },
        {
          "name": "Piano",
          "synonym": "Keys"
        },
        {
          "name": "Piano",
          "synonym": "Piano"
        },
        {
          "name": "Piccolo",
          "synonym": "Piccolo"
        },
        {
          "name": "Saxophone",
          "synonym": "Bari Sax"
        },
        {
          "name": "Saxophone",
          "synonym": "Baritone Sax"
        },
        {
          "name": "Saxophone",
          "synonym": "Saxophone"
        },
        {
          "name": "Saxophone",
          "synonym": "Soprano Sax"
        },
        {
          "name": "Saxophone",
          "synonym": "Tenor Sax"
        },
        {
          "name": "String Bass",
          "synonym": "Acoustic Bass"
        },
        {
          "name": "String Bass",
          "synonym": "String Bass"
        },
        {
          "name": "Tuba",
          "synonym": "Tuba"
        },
        {
          "name": "Trombone",
          "synonym": "Bass Trombone"
        },
        {
          "name": "Trombone",
          "synonym": "Trombone"
        },
        {
          "name": "Technology",
          "synonym": "Audio Equipment"
        },
        {
          "name": "Technology",
          "synonym": "Editing"
        },
        {
          "name": "Technology",
          "synonym": "Music Editing"
        },
        {
          "name": "Technology",
          "synonym": "Notation"
        },
        {
          "name": "Technology",
          "synonym": "Play Back"
        },
        {
          "name": "Technology",
          "synonym": "Recording"
        },
        {
          "name": "Technology",
          "synonym": "Technology"
        },
        {
          "name": "Theory",
          "synonym": "Composition"
        },
        {
          "name": "Theory",
          "synonym": "History"
        },
        {
          "name": "Theory",
          "synonym": "Music History"
        },
        {
          "name": "Theory",
          "synonym": "Music Theory"
        },
        {
          "name": "Theory",
          "synonym": "Rhythym"
        },
        {
          "name": "Theory",
          "synonym": "Theory"
        },
        {
          "name": "Theory",
          "synonym": "Tuning"
        },
        {
          "name": "Trumpet",
          "synonym": "Cornet"
        },
        {
          "name": "Trumpet",
          "synonym": "Trumpet"
        },
        {
          "name": "Ukulele",
          "synonym": "Ukulele"
        },
        {
          "name": "Voice",
          "synonym": "Sing"
        },
        {
          "name": "Voice",
          "synonym": "Singing"
        },
        {
          "name": "Voice",
          "synonym": "Vocals"
        },
        {
          "name": "Voice",
          "synonym": "Voice"
        },
        {
          "name": "Viola",
          "synonym": "Viola"
        },
        {
          "name": "Violin",
          "synonym": "Double Bass"
        },
        {
          "name": "Violin",
          "synonym": "Fiddle"
        },
        {
          "name": "Violin",
          "synonym": "Irish Fiddle"
        },
        {
          "name": "Violin",
          "synonym": "Violin"
        }
    ]
}