import {
    Button,
    CardBody,
    Collapse,
    InputGroup,
    Input,
    FormGroup,
    ButtonGroup,
    Label
  } from "reactstrap";
import React from "react";

class ToggleWrapper extends React.Component {

    constructor(props)
    {
        super(props);
        let timeOfDayLS = [];
        if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).timeOfDay)
        {                             
            timeOfDayLS=JSON.parse(localStorage.getItem('userSelections')).timeOfDay;
        }
        if(Array.isArray(timeOfDayLS))
        {
           this.state = {
               rSelected : timeOfDayLS,
               timeOfDayCheckboxes: timeOfDayLS
           }
        }
        else
        {
            this.state = {
                rSelected : [timeOfDayLS],
                timeOfDayCheckboxes: [timeOfDayLS]
            }
        }
    }
    onCheckboxBtnClick = (selected) => {
    var ltBox = ['BOTH'];
    var ltFromLS = [];
    if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).lessonMode
        && JSON.parse(localStorage.getItem('userSelections')).lessonMode.length>0)
    {
        if(Array.isArray(JSON.parse(localStorage.getItem('userSelections')).lessonMode))
        {
            ltFromLS = JSON.parse(localStorage.getItem('userSelections')).lessonMode;
        }
        else
        {
            ltFromLS[ltFromLS.length] = JSON.parse(localStorage.getItem('userSelections')).lessonMode;
        }
        for(var ltRec of ltFromLS)
        {
            if(ltRec==='Online')
            {
                ltBox[ltBox.length]='ONLINE';
            }
            else
            {
                ltBox[ltBox.length]='INSTORE';
            }
        }
    }
    if(ltBox.length===1 || ltBox.length===3)
    {
        ltBox = ["INSTORE","ONLINE","BOTH"];
    }
    var ssel = this.state.rSelected;
    var filterLabels = [];
    if(ssel.includes(selected.target.value))
    {
        ssel.splice(ssel.indexOf(selected.target.value), 1);
    }
    else
    {
        ssel.push(selected.target.value);
    }
    this.setState({timeOfDayCheckboxes:[], rSelected: ssel})
    var ls = JSON.parse(localStorage.getItem('userSelections'));
    Object.keys(ls).forEach((key) => {
        if('timeOfDay'==key)
        {
            ls[key]=ssel;
        }
    });
    localStorage.setItem('userSelections', JSON.stringify(ls));

    var query={};

    var min = 23;
    var max = 0;
    if(ssel.includes("1"))
    {
        if(5<min)
        {
            min=5;
        }
        if(12>max)
        {
            max=12;
        }
        filterLabels[filterLabels.length]="Morning";
    }
    if(ssel.includes("2"))
    {
        if(13<min)
        {
            min=13;
        }
        if(16>max)
        {
            max=16;
        }
        filterLabels[filterLabels.length]="Afternoon";
    }
    if(ssel.includes("3"))
    {
        if(17<min)
        {
            min=17;
        }
        if(23>max)
        {
            max=23;
        }
        filterLabels[filterLabels.length]="Evening";
    }
    if(ssel.length===0 || (ssel.includes('on') && ssel.length === 1))
    {
        min=0;
        max=23;
    }
    var dayOfWeekCheckedBoxesI = [];
    if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).daysOfWeek)
    {
        var checkedBoxesArray = JSON.parse(localStorage.getItem('userSelections')).daysOfWeek;
        var dayOfWeekCheckedBoxes = [];
        var minValue=8;
        var maxValue=0;
        for(let val of checkedBoxesArray)
        {
            dayOfWeekCheckedBoxes[dayOfWeekCheckedBoxes.length]=val;
            let dayEq = '';
            if(val=='monday' || val=='2')
            {
                dayEq = '2';
            }
            else if(val=='tuesday' || val=='3')
            {
                dayEq = '3';
            }
            else if(val=='wednesday' || val=='4')
            {
                dayEq = '4';
            }
            else if(val=='thursday' || val=='5')
            {
                dayEq = '5';
            }
            else if(val=='friday' || val=='6')
            {
                dayEq = '6';
            }
            else if(val=='saturday' || val=='7')
            {
                dayEq = '7';
            }
            else if(val=='sunday' || val=='1')
            {
                dayEq = '1';
            }

            const numValue = parseInt(dayEq);
            dayOfWeekCheckedBoxesI[dayOfWeekCheckedBoxesI.length]=numValue;
        }
    }
    query =
    {
        "query":
          {
            "bool":{
              "must":[
                {
                  "bool":
                  {
                    "must":[
                            {
                              "script": {
                                "script": {
                                  "source": "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}}}return false;}",
                                  "params": {
                                    "min": min,
                                    "max": max,
                                    "dayOfWeekCheckedBoxesI": dayOfWeekCheckedBoxesI,
                                    "lt": ltBox
                                  }
                                }
                              }
                            }
                        ]
                    }
                  }
              ]
            }
        }
    }
    if((ssel.includes('morning') || ssel.includes('1')) && (ssel.includes('evening') || ssel.includes('3')) && !(ssel.includes('afternoon') || ssel.includes('2')))
    {
        query =
        {
            "query":
              {
                "bool":{
                  "must":[
                    {
                      "bool":
                      {
                        "should":[
                                {
                                  "script": {
                                    "script": {
                                      "source": "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}}}return false;}",
                                      "params": {
                                        "min": 5,
                                        "max": 12,
                                        "dayOfWeekCheckedBoxesI": dayOfWeekCheckedBoxesI,
                                        "lt": ltBox
                                      }
                                    }
                                  }
                                },
                                {
                                  "script": {
                                    "script": {
                                      "source": "def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){def lt=avail[elem].splitOnToken(';')[1].splitOnToken('#')[1]; if(params.lt.contains(lt)){return true;}}}}}return false;}",
                                      "params": {
                                        "min": 17,
                                        "max": 23,
                                        "dayOfWeekCheckedBoxesI": dayOfWeekCheckedBoxesI,
                                        "lt": ltBox
                                      }
                                    }
                                  }
                                }
                            ]
                        }
                      }
                  ]
                }
            }
        }
    }
     this.props.setQuery({
         query,
         value: filterLabels
     })

     if(document.getElementById('prxlt'))
     {
         document.getElementById('prxlt').click();
     }
    }

    render() {
        return (
            <div>
                <Collapse isOpen={true}>
                    <CardBody>
                         <FormGroup check className="date" id="preferredTimePicker">
                         <FormGroup check>
                         <Label check>
                               <Input type="checkbox" name="morning" filterLabel="Morning"
                               onChange={this.onCheckboxBtnClick.bind(this)}
                               checked={this.state.rSelected.includes("1") || this.state.timeOfDayCheckboxes.includes('morning')
                                || this.state.timeOfDayCheckboxes.includes("1")} value="1" />
                                Morning (9 AM - Noon)<span className="form-check-sign" />
                        </Label>
                        </FormGroup>
                        <FormGroup check>
                        <Label check>
                               <Input type="checkbox" name="afternoon"
                               onChange={this.onCheckboxBtnClick.bind(this)}
                               checked={this.state.rSelected.includes("2") || this.state.timeOfDayCheckboxes.includes('afternoon')
                                || this.state.timeOfDayCheckboxes.includes("2")} value="2"/>
                                Afternoon (Noon - 5 PM)<span className="form-check-sign" />
                        </Label>
                        </FormGroup>
                        <FormGroup check>
                        <Label check>
                               <Input type="checkbox" name="evening"
                               onChange={this.onCheckboxBtnClick.bind(this)}
                               checked={this.state.rSelected.includes("3") || this.state.timeOfDayCheckboxes.includes('evening')
                                || this.state.timeOfDayCheckboxes.includes("3")} value="3"/>
                                Evening (After 5 PM)<span className="form-check-sign" />
                        </Label>
                        </FormGroup>
                        <FormGroup check>
                        <Label check>
                               <Input type="checkbox" name="prxpt" id="prxpt" style={{display:'none'}}
                               onChange={this.onCheckboxBtnClick.bind(this)}/>
                        </Label>
                        </FormGroup>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </div>
        )
    }
}

export default ToggleWrapper;