import React, { useEffect } from "react";

// core components
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";

const LoginPage = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();

  const login = async () => oktaAuth.signInWithRedirect();
  const logout = async () => oktaAuth.signOut('/');

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      history.push('/inst-listing');
    }
  }, [authState, history]);

  if(!authState) {
    return <div>Loading...</div>;
  }

  if(!authState.isAuthenticated) {
    return (
      <div className="layout">
			<div className="pill">
				<img
					src={'/ma-logo.svg'}
					alt="Music & Arts logo"
					width={60}
					height={60}
					className="logo"
				/>
				<div className="login-title">
					Associate SAM
				</div>
				<div className="login-text">Sign in to continue</div>
				<div>
					<button
						className="login-btn"
						onClick={login}
					>
						<img
							className="w-6"
							src={'/okta-color.svg'}
							alt="okta-color icon"
							width={20}
							height={20}
						/>
						<span>Continue with Okta</span>
					</button>
				</div>
			</div>
		</div>
    );
  }

  return (
    <div>
      <p>Logged in!</p>
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default LoginPage;

