import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";
import axios from 'axios';
import InstructorContactModal from "../sections/InstructorContactModal.js";
import BookNowModal from '../sections/BookNowModal.js';
import { searchProxyAPIUrl, searchProxyAPIKey } from '../../config.js';

function InstructorProfile(props) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [instructorObj, setInstructorObj] = React.useState({});
  const [contactModal, setContactModal] = React.useState(false);
  const [contactInstructor, setContactInstructor] = React.useState("");
  const [bookNowModal, setBookNowModal] = React.useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function handleContactReq(target, instData){
    setContactInstructor(instData);
   setContactModal(true);
}
function handleBookReq(target, instData){
  setContactInstructor(instData);
  setBookNowModal(true);
}

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  React.useEffect(() => {

    let instId = window.location.search;
    instId = window.location.search.split('id=');
    instId = instId[1];
    console.log(instructorObj)
    if (instructorObj && !instructorObj.id) {
    
    
  
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "x-api-key": searchProxyAPIKey
      }
    };
  
    let reqData = 
    {
     "query": {
     "match": {
     "instructorId.keyword": instId
     }
     }
    }
  
    axios.post(`${searchProxyAPIUrl}/iquery`, reqData, axiosConfig)
    .then(res => {
      if (res.data && res.data.hits && res.data.hits.hits[0] && res.data.hits.hits[0]._source) {
        setInstructorObj(res.data.hits.hits[0]._source);
      }
    })
    .catch(err => {
        console.error(err);
       
      }
    );
    }
  })

  const storeAddress = instructorObj.storeaddress2 ? `${instructorObj.storeaddress1} ${instructorObj.storeaddress2}` : `${instructorObj.storeaddress1}`;

  const hasImage = instructorObj && instructorObj.imagename && (instructorObj.imagename !== 'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png');
  const hideInstructorName = instructorObj && !instructorObj.shortbio && !hasImage;

  return (
    <>
      <div className="wrapper">
        <div className="profile-content section">
          <Container>
            <Row className="inst-profile-page">
            <Col className="ml-auto mr-auto left-panel" md="6">
              <div className="profile-picture">
                <div
                  className="fileinput fileinput-new"
                  data-provides="fileinput"
                >
                  <div className="fileinput-new img-no-padding text-center">
                  {instructorObj && instructorObj.imagename ?  <img
                          alt="avatar"
                          className="instructor-image"
                          src={instructorObj.imagename}
                        /> : <div className="avatar-placeholder instructor-image"></div>

                        }
                  </div>
                  <div className="name">
                  <div className="instructor-info">
              <h3 className="instructor-info_name">{instructorObj && !hideInstructorName ? instructorObj.firstname : 'Music & Arts Instructor'}</h3>
            </div>
                    <h4 className="title text-center">
                    Music Teacher, Professional <br />
                    </h4>
                  </div>
                  <div className="card-profile_location">
                    <div className="card-profile_location_icon"></div>
                    <p style={{fontWeight: 500}}>{`Music & Arts ${instructorObj.storecity} #${instructorObj.storeNumber}`}<br/>
                    {storeAddress}<br/>{`${instructorObj.storecity}, ${instructorObj.storestate}`}</p>
                        </div>
                  <Row>
                  <Col className="ml-auto mr-auto button-container" md="12">
                    <button style={{fontWeight: 'bold'}} className="btn btn-primary" onClick={(e) => {e.preventDefault(); handleBookReq(e, instructorObj)}}
                                            >Book Now</button>
                    <Button
                        className={`btn btn-secondary ${props.toggleContactBtn? "ind-btn-tgl" : ""}`}
                        color="white"
                        href="/instructor-profile"
                        name={instructorObj.name}
                        onClick={(e) => {e.preventDefault(); handleContactReq(e, instructorObj)}}
                        >
                        CONTACT ME
                    </Button>
                    <Modal
                        isOpen={contactModal}
                        toggle={() => setContactModal(false)}
                        className="modal-register"
                        >
                            <InstructorContactModal modalFn={setContactModal} instructor={contactInstructor}/>
                        </Modal>
                        <Modal
                        isOpen={bookNowModal}
                        toggle={() => setBookNowModal(false)}
                        className="modal-book-now"
                        >
                            <BookNowModal modalFn={setBookNowModal} instructor={contactInstructor}/>
                        </Modal>
                  </Col>
                </Row>
                <Row>
                  {/* <Col xs="4" className="instructor-info_joined">
                  <div>
                    <p className="text-center -label">Joined</p>
                    <p className="text-center">2020</p>
                  </div>
                  </Col>
                  <Col xs="8" className="instructor-info_availability">
                  <div className="instructor-info_availability_icon"></div>
                  <a href="#" className="instructor-info_availability_link">SEE AVAILABILITY</a>
                  </Col> */}
                </Row>
                  
                 <div className="basic-info">
                    <div className="basic-info-section">
                      <p className="info-label">Expertise</p>
                      
                      <div className="info-items">
                      {instructorObj && instructorObj.instruments ? instructorObj.instruments.map((item) => {
                                    return (
                                    <span className="info-item">{item}</span>
                                    )
                                }) : null
                              }
                      </div>
                    </div>
                    {/* <div className="basic-info-section">
                      <p className="info-label">Favorite Instruments</p>
                      <div className="info-items"><span className="info-item">Piano</span><span className="info-item">Acoustic Guitar</span></div>
                    </div>
                    <div className="basic-info-section">
                      <p className="info-label">Musical Interests</p>
                      <div className="info-items"><span className="info-item">Classical piano</span><span className="info-item">Playing guitar </span><span className="info-item">piano</span></div>
                    </div> */}
                  </div>
                </div>
              </div>
                <br />
                </Col>
                <div className="right-container">

                
            <Col className="ml-auto mr-auto bio-panel" md="6">
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                  <p className="info-panel-title">Bio</p>
                  {instructorObj && instructorObj.shortbio ? instructorObj.shortbio.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/&amp;/g, ' & ').replace(/&rsquo;/g, "'") : null}
                  </Col>
                </Row>
            </Col>
            
            <div className="lessons-panel">
              <p className="info-panel-title">Instruments I Teach</p>
              {instructorObj && instructorObj.instruments ? instructorObj.instruments.map((item) => {
                                    return (
                                    <p className="panel_bullet-item">{item}</p>
                                    )
                                }) : null
                              }
            </div>
            <div className="lesson-price-panel">
              <p className="info-panel-title">Lessons Price</p>
              <div className="lesson-price-panel_row">
                <div className="lesson-price-panel_col">
                  <p className="panel_bullet-item">30 Min</p>
                  <p className="panel_info">Book four 30 minute lessons</p>
                </div>
                <div className="lesson-price-panel_col">
                  <p className="lesson-price-panel_price">{instructorObj && instructorObj.thirtyprice ? `$${instructorObj.thirtyprice}.00` : null}</p>
                </div>
              </div>
              <div className="lesson-price-panel_row">
                <div className="lesson-price-panel_col">
                  <p className="panel_bullet-item">60 Min</p>
                  <p className="panel_info">Book four 60 minute lessons</p>
                </div>
                <div className="lesson-price-panel_col">
                  <p className="lesson-price-panel_price">{instructorObj && instructorObj.sixtyprice ? `$${instructorObj.sixtyprice}.00` : null}</p>
                </div>
              </div>
            </div>
            {/* <div className="bands-panel">
              <p className="info-panel-title">Favorite Bands</p>
              <p>
              {instructorObj && instructorObj.bands ? instructorObj.bands.map((item) => {
                                    return (
                                    <span> {item},</span>
                                    )
                                }) : null
                              }
              </p>
            </div> */}
            </div>
            {/* <div className="comments-section">
              <div className="comment-panel">
                <div className="comment-panel_top">
                  <div className="comment-panel_top_avatar">
                    <img src="https://d3tz2xd2453h51.cloudfront.net/6.jpg" className="comment-panel_top_avatar_img"></img>
                  </div>
                  <div className="comment-panel_top_name-review">
                    <div className="comment-panel_top_name-review_name">
                      Steven Gerrad
                    </div>
                    <div className="comment-panel_top_name-review_review">

                    </div>
                  </div>
                  <div className="comment-panel_top_time">
                    <p>52 MIN AGO</p>
                  </div>
                </div>
                <div className="comment-panel_bottom">
                  <p className="comment-panel_bottom_title">Good Service!!</p>
                  <p className="comment-panel_bottom_body">Proin venenatis elit arcu, non rhoncus dui consectetur nec. Nam a magna auctor, luctus mauris non, ornare augue. Fusce dictum eu nulla quis bibendum.</p>
                </div>
              </div>
              <div className="comment-panel">
                <div className="comment-panel_top">
                  <div className="comment-panel_top_avatar">
                    <img src="https://d3tz2xd2453h51.cloudfront.net/6.jpg" className="comment-panel_top_avatar_img"></img>
                  </div>
                  <div className="comment-panel_top_name-review">
                    <div className="comment-panel_top_name-review_name">
                      Steven Gerrad
                    </div>
                    <div className="comment-panel_top_name-review_review">

                    </div>
                  </div>
                  <div className="comment-panel_top_time">
                    <p>52 MIN AGO</p>
                  </div>
                </div>
                <div className="comment-panel_bottom">
                  <p className="comment-panel_bottom_title">Good Service!!</p>
                  <p className="comment-panel_bottom_body">Proin venenatis elit arcu, non rhoncus dui consectetur nec. Nam a magna auctor, luctus mauris non, ornare augue. Fusce dictum eu nulla quis bibendum.</p>
                </div>
              </div>
            </div> */}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default InstructorProfile;
