import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuthConfig = {
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    transformAuthState: async (oktaAuth, authState) => {
      if (!authState.isAuthenticated) {
        return authState;
      }
      // extra requirement: user must have valid Okta SSO session
      const user = await oktaAuth.token.getUserInfo();
      authState.isAuthenticated = !!user; // convert to boolean
      authState.users = user; 
      let storeNumber = user.store_number;
      if(storeNumber=='987' || storeNumber=='9900')
        {
            storeNumber='0001';
        }
        storeNumber = String(storeNumber).padStart(4, '0');
      localStorage.setItem("storeNumber", storeNumber);
        localStorage.setItem("userGraphDetails", JSON.stringify(authState.users));// also store user object on authState
      return authState;
    }
  };

const oktaAuth = new OktaAuth(oktaAuthConfig);

export { oktaAuth };
